
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_details = window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_details || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_details = {...window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_details, ...{
    "date_and_time": "Date &amp; Time",
    "description": "Description",
    "read_more": "Read More",
    "read_less": "Read Less",
    "location": "Location",
    "get_directions": "Get Directions",
    "rsvp": "RSVP<i class=\"far fa-external-link\"></i>",
    "rsvp_not_required": "RSVP Not Required",
    "rsvp_closed": "RSVP Closed",
    "rsvp_coming_soon": "RSVP Coming Soon",
    "details_are_hidden": "Details are available to current students and alumni."
}
}
    