import { storeProvider } from 'ReduxHelpers';
import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_profile_list_card.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { openChatAndCreateDraftChannelIfNeeded } from 'Messaging';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentProfileListCard', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const isMobileMixin = $injector.get('isMobileMixin');
        const EventLogger = $injector.get('EventLogger');
        const candidateListCardViewHelper = $injector.get('candidateListCardViewHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const DialogModal = $injector.get('DialogModal');
        const scrollHelper = $injector.get('scrollHelper');

        return {
            restrict: 'E',
            scope: {
                careerProfile: '<',
                preview: '<?',
            },
            templateUrl,

            link(scope) {
                isMobileMixin.onLink(scope);
                NavigationHelperMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.logEvent = (name, payload) => {
                    EventLogger.log(
                        name,
                        angular.extend(payload || {}, {
                            candidate_id: scope.careerProfile.user_id,
                        }),
                    );
                };

                function loadUserInfo() {
                    // the helper does all the work to summarize the data in a clean form
                    scope.userInfo = candidateListCardViewHelper.getSummarizedProfileInfo(
                        scope.careerProfile,
                        scope.preview,
                    );

                    // these careerProfile items change depending on the preview and privacy settings
                    const isPreviewAndAnon =
                        scope.preview && scope.careerProfile.pref_student_network_privacy === 'anonymous';

                    const isPreviewAndFull =
                        scope.preview && scope.careerProfile.pref_student_network_privacy === 'full';

                    scope.showContact = isPreviewAndFull || (!scope.preview && !scope.careerProfile.anonymized);
                    scope.showLinkedIn = isPreviewAndAnon ? false : !!scope.careerProfile.li_profile_url;
                    scope.showGithub = isPreviewAndAnon ? false : !!scope.careerProfile.github_profile_url;
                    scope.showMoreWorkExperiences = isPreviewAndAnon
                        ? false
                        : scope.userInfo.moreWorkExperiencesNum > 0;

                    scope.programs = scope.userInfo.programs;
                }

                // if we're previewing the card in the application or the network settings page, this
                // value can change on the fly, which should re-render the info the card displays
                scope.$watch('careerProfile.pref_student_network_privacy', loadUserInfo);

                /**
                 * Actions
                 */
                scope.contactStudent = () => {
                    if (scope.userInfo?.canSendSendbirdMessageToUser && scope.userInfo.userId) {
                        storeProvider.dispatch(
                            openChatAndCreateDraftChannelIfNeeded({
                                currentUserId: scope.currentUser.id,
                                token: scope.currentUser.sendbird_access_token,
                                userToMessageId: scope.userInfo.userId,
                            }),
                        );
                        return;
                    }

                    DialogModal.alert({
                        content:
                            '<contact-form target-email="targetEmail" career-profile="careerProfile" user-info="userInfo" on-finish="onFinish"></contact-form>',
                        classes: ['no-title'],
                        blurTargetSelector: '#sp-page',
                        scope: {
                            careerProfile: scope.careerProfile,
                            userInfo: scope.userInfo,
                            onFinish() {
                                DialogModal.removeAlerts();
                            },
                        },
                    });
                };

                scope.editProfile = section => {
                    scope.$emit('gotoEditProfileSection', section);
                };

                scope.scrollTo = id => {
                    scrollHelper.scrollToElement($(id), true, -20);
                };
            },
        };
    },
]);
