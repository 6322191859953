import { isEqual } from 'lodash/fp';
import { InstitutionId } from 'Institutions';
import angularModule from '../student_network_module';

/*
    This class is responsible for loading and setting up the map data layer representing our students and alumni.
*/
angularModule.factory('StudentsMapLayer', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $window = $injector.get('$window');
        const StudentNetworkMap = $injector.get('StudentNetworkMap');
        const StudentNetworkFilterSet = $injector.get('StudentNetworkFilterSet');
        const TranslationHelper = $injector.get('TranslationHelper');
        const HasLocation = $injector.get('HasLocation');
        const EventLogger = $injector.get('EventLogger');
        const Cohort = $injector.get('Cohort');

        return SuperModel.subclass(function () {
            Object.defineProperty(this.prototype, 'inAdvancedSearchMode', {
                get() {
                    return this._inAdvancedSearchMode;
                },
                set(value) {
                    this._inAdvancedSearchMode = value;

                    if (!value) {
                        this.focusedClusterFeature = null;
                    }

                    if (this.isAMap) {
                        // Compare state to reduce react render.
                        const state = {
                            focusedFeature: this.focusedClusterFeature,
                            inAdvancedSearchMode: this.inAdvancedSearchMode,
                        };
                        this.handlers.setAdvancedSearchModel(prev => (isEqual(state, prev) ? prev : state));
                    }
                },
            });

            Object.defineProperty(this.prototype, 'focusedClusterFeature', {
                get() {
                    return this._focusedClusterFeature;
                },
                set(feature) {
                    if (isEqual(feature, this._focusedClusterFeature)) {
                        return;
                    }
                    const wasAlreadyInAdvancedSearchMode = this.inAdvancedSearchMode;
                    this._focusedClusterFeature = feature;
                    if (feature) {
                        this.inAdvancedSearchMode = true;
                    }

                    // we don't need to refresh if we're entering advanced search mode; it will do it for us
                    if (wasAlreadyInAdvancedSearchMode) {
                        this.refreshStudentProfiles();
                    }

                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    if (this.isAMap) {
                        // Compare state to reduce react render.
                        const state = {
                            focusedFeature: this.focusedClusterFeature,
                            inAdvancedSearchMode: this.inAdvancedSearchMode,
                        };
                        this.handlers.setAdvancedSearchModel(prev => (isEqual(state, prev) ? prev : state));
                    }
                },
            });

            Object.defineProperty(this.prototype, 'supportsRefreshOnIdleMapEvent', {
                value: true,
            });

            Object.defineProperty(this.prototype, 'isAMap', {
                get() {
                    // There is no obvious attribute of Google Map to distinguish who is it, so use the AMap's CLASS_NAME.
                    return this.map?.CLASS_NAME === 'AMap.Map';
                },
            });

            return {
                //---------------------
                // External Interface
                //---------------------
                // Only the AMap will give the 'handlers' parameter.
                initialize(map, handlers) {
                    this.map = map;
                    this.name = 'students';
                    this.handlers = handlers;

                    this.listLimit = 10;
                    this.preloadedParams = {
                        // The serverLimit should be twice the value of the listLimit to ensure that the
                        // next page of profiles can be completely filled out if the user navigates to it.
                        serverLimit: this.listLimit * 2,

                        // By making minLength a little larger than twice the listLimit, it ensures that the next page
                        // always has enough profiles to be filled, if possible. This means more frequent trips to the
                        // server for more profiles, but prevents the UI from attaching profiles to the bottom of the
                        // list in a jarring way and then suddenly updating the counter upon doing so.
                        minLength: this.listLimit * 2 + 1,
                        offset: 0,
                    };

                    this.loading = 0;

                    this.lastZoom = -1;
                    this.markerWidth = 12; // empirically, this looks good

                    // state tracking for the mobile view
                    this.mobileState = {
                        expanded: false,
                    };

                    // state tracking for the mini view
                    this.currentClassFilter = 'all';

                    // control whether we're showing the advanced search view
                    this.inAdvancedSearchMode = false;

                    // use for the advanced search (list) view
                    this.advancedFilters = {};

                    this.programTypeConfigs = Cohort.programTypes.filter(
                        programTypeConfig =>
                            programTypeConfig.supportsNetworkAccess && !programTypeConfig.inDevelopment(),
                    );

                    this.allProgramTypeFilterValues = this.programTypeConfigs.map(
                        programTypeConfig => programTypeConfig.key,
                    );

                    this.quanticDegreeProgramTypeFilterValues = this.programTypeConfigs
                        .filter(config => config.institutionID === InstitutionId.quantic && config.isDegreeProgram)
                        .map(config => config.key);
                    this.valarDegreeProgramTypeFilterValues = this.programTypeConfigs
                        .filter(config => config.institutionID === InstitutionId.valar && config.isDegreeProgram)
                        .map(config => config.key);

                    this.mbaProgramTypeFilterValues = this.allProgramTypeFilterValues.filter(Cohort.isMBA);
                    this.embaProgramTypeFilterValues = this.allProgramTypeFilterValues.filter(Cohort.isEMBA);
                    this.msbaProgramTypeFilterValues = this.allProgramTypeFilterValues.filter(Cohort.isMSBA);
                    this.msseProgramTypeFilterValues = this.allProgramTypeFilterValues.filter(Cohort.isMSSE);
                    this.execEdProgramTypeFilterValues = this.allProgramTypeFilterValues.filter(Cohort.isExecEd);
                    // filters are used to query the API for the map clusters, as well as the list of profiles
                    this.filters = {
                        program_type: this.allProgramTypeFilterValues,
                        only_local: true,
                    };

                    this.advancedFilterKeys = [
                        'places',
                        'keyword_search',
                        'class',
                        'student_network_looking_for',
                        'student_network_interests',
                        'industries',
                        'alumni',
                        'user_id',
                    ];

                    this.translationHelper = new TranslationHelper('student_network.network_map_filter_box');
                    this.careersFieldsTranslationHelper = new TranslationHelper('careers.field_options');
                    this.fieldsTranslationHelper = new TranslationHelper('student_network.field_options');

                    this._resetSelectedFilterSummary();
                    this.resetAdvancedFilters();
                },

                setClassFilter(value) {
                    if (value === 'mine') {
                        this.filters.cohort_id = this.myClassCohortId; // Note: student_network_map_view_model.js sets this on us via updateMyClassCohortId()
                        delete this.filters.program_type;
                        this.currentClassFilter = value;
                        this.advancedFilters.class = ['mine'];
                    } else if (value === 'all') {
                        delete this.filters.cohort_id;
                        delete this.filters.program_type;
                        this.currentClassFilter = value;
                        this.advancedFilters.class = [];
                    } else if (Array.isArray(value)) {
                        const valueCopy = [...value];
                        const mineIndex = valueCopy.findIndex(val => val === 'mine');
                        if (mineIndex >= 0) {
                            valueCopy.splice(mineIndex, 1);
                            this.filters.program_type = valueCopy;
                            // this filter is more of a temporary option to keep the my class option working the same
                            // until we decide how to handle users with multiple programs in the network.
                            // this could be changed to be an array of all of their network cohorts or stay
                            // as a single one for their "active" program
                            this.filters.include_my_cohort = this.myClassCohortId;
                        } else {
                            this.filters.program_type = valueCopy;
                            delete this.filters.include_my_cohort;
                        }
                        this.advancedFilters.class = value;
                        this.currentClassFilter = value;
                        delete this.filters.cohort_id;
                    } else {
                        throw new Error('Unsupported class filter');
                    }
                },

                deactivate() {
                    // Only the Google map needs to clear the data/status.
                    if (!this.isAMap) {
                        this.activated = false;
                        this._clearStudentFeatures();
                        this.lastZoom = -1;
                        this.lastFilters = undefined;
                        this.lastViewports = undefined;
                        _.invokeMap(this.listeners, 'remove');
                    }
                    this.loading = 0;
                },

                activate() {
                    // Only the Google map needs to clear the data/status.
                    if (!this.isAMap) {
                        this.activated = true;
                        this.listeners = [
                            // events that mean we should consider refreshing
                            $window.google.maps.event.addListener(
                                this.map,
                                'resize',
                                _.debounce(this.refresh.bind(this), 250),
                            ),
                        ];
                    }
                },

                refresh() {
                    if (this.loading || !this.activated) {
                        return;
                    }

                    this._loadStudentMarkers(this.filters);
                },

                applyFilters(filters) {
                    // check if anything has changed, and if so, make use of them and clear any focused clusters
                    if (filters && !isEqual(filters, this.filters)) {
                        // Keep the filters stored in this model
                        // separate from the ones bound to the UI in
                        // network-map-filter-box
                        this.filters = angular.copy(filters);

                        // If the user changes filters while a particular cluster is
                        // focused, we want to go back to the full map (at least
                        // for now)
                        this.focusedClusterFeature = null;
                    }

                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    // Google Map needs to refresh by itself.
                    if (this.isAMap) {
                        this.handlers.setFilters(prev =>
                            isEqual(prev, this.filters) ? prev : angular.copy(this.filters),
                        );
                    } else {
                        this.refresh();
                    }
                    this.refreshStudentProfiles();
                },

                applyAdvancedFilters() {
                    this.focusedClusterFeature = null;
                    this._applyAdvancedFiltersToFilters();
                    this.mobileState.expanded = false;
                    this._setHasAdvancedFilters();
                    this._setMobileHeader();
                },

                resetAdvancedFilters() {
                    this.advancedFilters = this.advancedFilters || {};

                    _.forEach(this.advancedFilterKeys, key => {
                        if (key === 'user_id') {
                            this.advancedFilters[key] = [];
                        }
                        if (key === 'class') {
                            this.advancedFilters[key] = [];
                            delete this.filters.program_type;
                            delete this.filters.include_my_cohort;
                            this.currentClassFilter = 'all';
                        } else if (key === 'alumni') {
                            this.advancedFilters[key] = null;
                        } else if (key === 'keyword_search') {
                            this.advancedFilters[key] = undefined;
                        } else {
                            this.advancedFilters[key] = [];
                        }

                        if (this.filters) {
                            delete this.filters[key];
                        }
                    });

                    this._resetSelectedFilterSummary();
                    this._setHasAdvancedFilters();
                },

                addInterestToFilters(interest) {
                    this.filters.student_network_interests = this.filters.student_network_interests || [];

                    if (!this.filters.student_network_interests.includes(interest))
                        this.filters.student_network_interests.push(interest);

                    this.advancedFilters.student_network_interests = this.filters.student_network_interests;

                    this._setHasAdvancedFilters();
                    this._setMobileHeader();
                },

                //---------------------
                // Data Loading
                //---------------------

                refreshStudentProfiles() {
                    // don't actually search if the list isn't visible
                    if (!this.inAdvancedSearchMode) {
                        // clear out filter set
                        this.studentProfileFilterSet = undefined;
                        return;
                    }

                    if (this.focusedClusterFeature) {
                        this.clusterLat = this._degreesToDms(this.focusedClusterFeature.geometry.coordinates[1], true);
                        this.clusterLong = this._degreesToDms(
                            this.focusedClusterFeature.geometry.coordinates[0],
                            false,
                        );
                    } else {
                        delete this.clusterLat;
                        delete this.clusterLong;
                    }

                    this._createStudentProfileFilterSet();
                },

                _createStudentProfileFilterSet() {
                    const sort = ['BEST_SEARCH_MATCH_FOR_HM'];
                    const newFilterSet = new StudentNetworkFilterSet(
                        this.filters,
                        sort,
                        this.preloadedParams,
                        this.focusedClusterFeature,
                    );

                    if (!this.studentProfileFilterSet || !this.studentProfileFilterSet.isEqual(newFilterSet)) {
                        this.studentProfileFilterSet = newFilterSet;
                        this.studentProfileFilterSet.ensureStudentProfilesPreloaded();
                    }
                },

                _loadStudentMarkers(filters) {
                    if (!this.map) {
                        return;
                    }

                    const zoom = this.map.getZoom();
                    const viewports = this._getViewports();

                    // if we can't fetch the viewport yet, it's because the getBounds() is returning undefined for the
                    // google map. We've seen this sporadically on some mobile devices, possibly caused by the tiles not
                    // having been loaded yet. The hope is that the `idle` event listener in student_network_map_view_model.js
                    // will trigger once all map stuff is available and properly refresh at that point.
                    // ref: https://stackoverflow.com/questions/2832636/google-maps-api-v3-getbounds-is-undefined
                    if (!viewports) {
                        return;
                    }

                    // only load if the zoom has changed
                    if (
                        zoom === this.lastZoom &&
                        angular.equals(filters, this.lastFilters) &&
                        angular.equals(viewports, this.lastViewports)
                    ) {
                        return;
                    }
                    if (zoom !== this.lastZoom || !angular.equals(filters, this.lastFilters)) {
                        this.focusedClusterFeature = null;
                    }
                    this.lastZoom = zoom;
                    this.lastFilters = _.clone(filters);
                    this.lastViewports = _.clone(viewports);

                    this.loading += 1;

                    StudentNetworkMap.index({
                        filters: angular.merge(
                            {
                                viewports,
                                zoom,
                                marker_width: this.markerWidth,
                            },
                            filters,
                        ),
                    }).then(response => {
                        if (this.activated) {
                            this._addGeoJsonToMap(response.result[0]);
                            this.loading -= 1;
                        }
                    });
                },

                // remove old features
                _clearStudentFeatures() {
                    this.studentFeatures = undefined;
                },

                _addGeoJsonToMap(geoJSON) {
                    this.studentGeoJSON = geoJSON;

                    if (!this.studentGeoJSON || !this.map) {
                        return;
                    }

                    // trigger the view refresh
                    this.studentFeatures = _.map(this.studentGeoJSON.features, feature => {
                        // We add the uid so that when we zoom or pan around the map, we don't replace
                        // existing features with identical replacements.  See also the track-by in network_map.html
                        feature.uid = [feature.properties.cluster_hull, feature.properties.count].join(' | ');
                        return feature;
                    });
                },

                //--------------------
                // Helper functions
                //--------------------

                _degreesToDms(decimal, latitude) {
                    let degrees = 0;
                    let minutes = 0;
                    let seconds = 0;
                    let direction = 'N';

                    // set direction; north assumed
                    if (latitude && decimal < 0) {
                        direction = 'S';
                    } else if (!latitude && decimal < 0) {
                        direction = 'W';
                    } else if (!latitude) {
                        direction = 'E';
                    }

                    // get absolute value of decimal
                    const d = Math.abs(decimal);

                    // get values
                    degrees = Math.floor(d);
                    seconds = (d - degrees) * 3600;
                    minutes = Math.floor(seconds / 60);
                    seconds = Math.floor(seconds - minutes * 60); // reset seconds

                    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
                },

                _boundsToLatLngs(bounds) {
                    const sw = bounds.getSouthWest();
                    const ne = bounds.getNorthEast();
                    const lat1 = sw.lat();
                    const lat2 = ne.lat();
                    const lng1 = sw.lng();
                    const lng2 = ne.lng();
                    return [lng1, lat1, lng2, lat2];
                },

                _paddedViewportBounds(bounds) {
                    const [lng1, lat1, lng2, lat2] = this._boundsToLatLngs(bounds);
                    const distanceToExtend = 10;

                    // extend the bounds a bit
                    // Google does the hard work for us and correctly wraps lat/lng values here, so we don't need to worry about it
                    const furtherSW = new $window.google.maps.LatLng({
                        lat: lat1 - distanceToExtend,
                        lng: lng1 - distanceToExtend,
                    });

                    const furtherNE = new $window.google.maps.LatLng({
                        lat: lat2 + distanceToExtend,
                        lng: lng2 + distanceToExtend,
                    });

                    let biggerBounds = bounds.extend(furtherSW);
                    biggerBounds = biggerBounds.extend(furtherNE);

                    return biggerBounds;
                },

                _getViewports() {
                    const bounds = this.map.getBounds();

                    // this can happen if the map isn't fully loaded yet (conflicting information about what counts as fully loaded)
                    if (!bounds) {
                        return null;
                    }

                    // Get viewports, one for each side of the international date line, when the viewport crosses it
                    let viewports = [];
                    const paddedBounds = this._paddedViewportBounds(bounds);
                    const [lng1, lat1, lng2, lat2] = this._boundsToLatLngs(paddedBounds);

                    // If SW longitude is greater than NE longitude, the coordinates have wrapped across the date line
                    const crossesDateLine = lng1 > lng2;

                    // add naive single viewport based on paddedBounds (might cross date line)
                    viewports.push([lng1, lat1, lng2, lat2]);

                    if (crossesDateLine) {
                        // adjust first viewport to be the part of the map to the right of the date line
                        // adjust its longitude
                        viewports[0][0] = -179.999;

                        // create a second viewport that is the part of the map to the left of the date line
                        viewports.push([lng1, lat1, 180, lat2]);
                    }

                    viewports = viewports.map(vp => vp.join(','));
                    return viewports;
                },

                _getFilterSummary(key) {
                    let summary;
                    if (this.advancedFilters) {
                        if (key === 'alumni' && angular.isDefined(this.advancedFilters[key])) {
                            if (this.advancedFilters[key] === true) {
                                summary = this.translationHelper.get('graduated');
                            } else if (this.advancedFilters[key] === false) {
                                summary = this.translationHelper.get('not_graduated');
                            }
                        } else if (key === 'keyword_search' && this.advancedFilters[key]) {
                            summary = `"${this.advancedFilters[key]}"`;
                        } else if (
                            key === 'class' &&
                            this.allProgramTypeFilterValues.includes(this.advancedFilters[key])
                        ) {
                            summary = Cohort.networkProgramTitle(this.advancedFilters[key]);
                        } else if (_.some(this.advancedFilters[key])) {
                            if (key === 'industries') {
                                summary = this.careersFieldsTranslationHelper.get(this.advancedFilters[key][0]);
                            } else if (key === 'student_network_interests') {
                                summary = this.advancedFilters[key][0];
                            } else if (key === 'student_network_looking_for') {
                                summary = this.fieldsTranslationHelper.get(this.advancedFilters[key][0]);
                            } else if (key === 'places') {
                                const firstPlace = this.filters[key][0];
                                summary = HasLocation.localizedLocationOrLocationString(firstPlace);
                            } else if (key === 'user_id') {
                                summary = undefined;
                            } else if (key === 'class') {
                                summary =
                                    this.advancedFilters[key][0] === 'mine'
                                        ? this.translationHelper.get('mine')
                                        : Cohort.networkProgramTitle(this.advancedFilters[key][0]);
                            } else {
                                summary = this.translationHelper.get(this.advancedFilters[key]);
                            }

                            const moreCount = this.advancedFilters[key].length - 1;
                            if (Array.isArray(this.advancedFilters[key]) && moreCount > 0) {
                                summary += this.translationHelper.get(
                                    `plus_n_${key}`,
                                    {
                                        count: moreCount,
                                    },
                                    undefined,
                                    'messageformat',
                                );
                            }
                        }
                    }

                    return summary ? `<span>${summary}</span> ` : null;
                },

                _setHasAdvancedFilters() {
                    // Note: this assumes that all filters are lists
                    this.hasAdvancedFilters = _.chain(this.advancedFilters)
                        .map((val, key) => {
                            // we only want hasAdvancedFilters to be true if 'class' is an non empty array filter
                            // that also doesn't match the simple search options (i.e my_class, all_mba, etc)
                            if (key === 'class') {
                                if (!Array.isArray(val) || !val.length) return false;

                                return (
                                    !isEqual(['mine'], val) &&
                                    !(
                                        this.embaProgramTypeFilterValues.every(prog => val.includes(prog)) &&
                                        val.length === this.embaProgramTypeFilterValues.length
                                    ) &&
                                    !(
                                        this.mbaProgramTypeFilterValues.every(prog => val.includes(prog)) &&
                                        val.length === this.mbaProgramTypeFilterValues.length
                                    ) &&
                                    !(
                                        this.execEdProgramTypeFilterValues.every(prog => val.includes(prog)) &&
                                        val.length === this.execEdProgramTypeFilterValues.length
                                    )
                                );
                            }

                            if (['alumni', 'keyword_search'].includes(key)) return val || undefined;

                            // the values of all other filters are lists of desired options
                            return _.some(val);
                        })
                        .some()
                        .value();
                },

                _applyAdvancedFiltersToFilters() {
                    _.forEach(this.advancedFilterKeys, key => {
                        // Some filters, like `alumni`, need to support a `false` values
                        if (
                            angular.isUndefined(this.advancedFilters[key]) ||
                            this.advancedFilters[key] === null ||
                            this.advancedFilters[key].length === 0
                        ) {
                            if (key === 'class') {
                                this.setClassFilter('all');
                            } else {
                                delete this.filters[key];
                            }
                        } else if (key === 'class') {
                            this.setClassFilter(this.advancedFilters[key]);
                        } else {
                            let value = angular.copy(this.advancedFilters[key]);
                            if (key === 'keyword_search') {
                                value = encodeURIComponent(value);
                            } else if (key === 'student_network_interests') {
                                value = value.map(element => encodeURIComponent(element));
                            }
                            this.filters[key] = value;
                        }
                    });

                    const loggableFilters = [
                        'places',
                        'keyword_search',
                        'student_network_looking_for',
                        'student_network_interests',
                        'industries',
                        'alumni',
                        'program_type',
                        'cohort_id',
                        'user_id',
                    ];
                    const ignorableFilters = ['only_local', 'include_my_cohort'];
                    const unexpectedFilters = _.chain(this.filters)
                        .keys()
                        .difference(ignorableFilters)
                        .difference(loggableFilters)
                        .value();
                    if (_.some(unexpectedFilters)) {
                        $injector
                            .get('ErrorLogService')
                            .notifyInProd(
                                `Unknown filters added to student network:${JSON.stringify(unexpectedFilters)}`,
                                undefined,
                                {
                                    level: 'warning',
                                },
                            );
                    }
                    const payload = {};
                    _.forEach(loggableFilters, key => {
                        if (!_.has(this.filters, key)) {
                            return;
                        }
                        if (key === 'places') {
                            payload.filters_places = _.map(this.filters[key], place =>
                                HasLocation.locationString(place),
                            );
                        } else {
                            payload[`filters_${key}`] = this.filters[key];
                        }
                    });
                    EventLogger.log('student_network:applied_search_filters', payload);
                },

                _resetSelectedFilterSummary() {
                    this.selectedFiltersSummary = `<span>${this.translationHelper.get(
                        'no_preferences_selected',
                    )}</span>`;
                },

                _setMobileHeader() {
                    const mobileHeaders = _.compact(_.map(this.advancedFilterKeys, key => this._getFilterSummary(key)));

                    if (_.some(mobileHeaders)) {
                        this.selectedFiltersSummary = mobileHeaders.join('');
                    } else {
                        this._resetSelectedFilterSummary();
                    }
                },
            };
        });
    },
]);
