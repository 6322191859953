import cacheAngularTemplate from 'cacheAngularTemplate';
import { getRelevantCohorts } from 'Users';
import angularModule from './student_network_module';
import template from '../views/network_map.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('networkMap', [
    '$injector',

    function factory($injector) {
        const StudentNetworkMapViewModel = $injector.get('StudentNetworkMapViewModel');
        const RouteAssetLoader = $injector.get('Navigation.RouteAssetLoader');
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const NgMap = $injector.get('NgMap');
        const $rootScope = $injector.get('$rootScope');
        const EventLogger = $injector.get('EventLogger');
        const guid = $injector.get('guid');
        const isMobileMixin = $injector.get('isMobileMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                eventId: '<?',
                showEvents: '<?',
            },
            link(scope) {
                isMobileMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'showRecommendedEventsList', {
                    get() {
                        return (
                            scope.studentNetworkMapViewModel &&
                            scope.studentNetworkMapViewModel.eventsMapLayer &&
                            scope.studentNetworkMapViewModel.eventsMapLayer.showRecommendedEventsList
                        );
                    },
                });

                Object.defineProperty(scope, 'searchIds', {
                    get() {
                        const search = $location.search();
                        return search?.ids;
                    },
                });

                scope.showPartialAccessModal = !scope.currentUser.hasAccessToFullStudentNetworkProfiles;

                scope.dismissPartialAccessModal = () => {
                    scope.showPartialAccessModal = false;
                };

                scope.mapStyles = $injector.get('STUDENT_NETWORK_MAP_STYLE');

                // set the center of the map based on window width
                const width = $window.innerWidth; // US only

                const userPlaceDetails = scope.currentUser?.career_profile?.place_details;
                const { lat = 30, lng = -100 } = userPlaceDetails || {};
                const centerLat = width > 1000 ? 23.45 : lat;
                let centerLng = lng;

                if (width > 1400) {
                    // US, EU, Asia
                    centerLng = -7.39;
                } else if (width > 1000) {
                    // US, EU
                    centerLng = -42;
                }

                scope.center = [centerLat, centerLng];

                function logGetMapEvent(eventType, id, start, attempts) {
                    EventLogger.log(
                        eventType,
                        {
                            duration_total: new Date() - start,
                            attempts,
                            get_map_id: id,
                        },
                        {
                            segmentio: false,
                        },
                    );
                }

                function getMap(id = guid.generate(), start = new Date(), attempts = 1) {
                    // It's possible that the user navigated away while getMap was running.
                    // In that case, give up
                    if (scope.$$destroyed) {
                        return undefined;
                    }

                    return NgMap.getMap({
                        // https://github.com/allenhwkim/angularjs-google-maps/issues/614
                        timeout: 10 * 1000,
                        id: 'student-network-map',
                    })
                        .then(map => {
                            logGetMapEvent('ng_map:got_map', id, start, attempts);
                            return map;
                        })
                        .catch(() => {
                            // assuming this was a timeout.  Log and keep waiting
                            // we stop logging at 5 attempts, since people almost always give or succeed
                            // by this point. If they don't, it's usuall a backgrounded tab, which might
                            // continue logging for hours, otherwise.
                            if (attempts <= 5) {
                                logGetMapEvent('ng_map:slow_loading_map', id, start, attempts);
                            }
                            return getMap(id, start, attempts + 1);
                        });
                }

                // Using RouteAssetLoader here instead of in route-resolvers because we don't
                // really want to block the whole page until the google api loads.  We really
                // just want to block the map
                RouteAssetLoader.loadGooglePlacesDependencies()
                    .then(() => {
                        if (!window.google?.maps) {
                            throw new Error('Google Maps API not available.');
                        }

                        scope.googleMapsLoaded = true;
                        scope.googleMapsFailedToLoad = false;
                        return getMap();
                    })
                    .then(map => {
                        // It's possible that the user navigated away while getMap was running,
                        // in which case we do not want to create a studentNetworkMapViewModel
                        if (scope.$$destroyed) {
                            return;
                        }

                        // Once the api is loaded and the map has been placed (invisibly) on the screen,
                        // we can create a StudentNetworkMapViewModel and show the map.
                        StudentNetworkMapViewModel.onGoogleApiLoaded();
                        scope.studentNetworkMapViewModel = new StudentNetworkMapViewModel(
                            map,
                            getRelevantCohorts($rootScope.currentUser).map(cohort => cohort.id),
                            scope.eventId,
                            scope.showEvents,
                        );
                    })
                    .catch(() => {
                        scope.googleMapsFailedToLoad = true;
                    });

                scope.$on('$destroy', () => {
                    if (scope.studentNetworkMapViewModel) {
                        scope.studentNetworkMapViewModel.destroy();
                    }
                });

                // Watch for changes to the `ids` query parameter and update the search accordingly.
                // The parameter can change when the user clicks 'View in Network' on a Messaging profile
                // while already navigated to the network route.
                scope.$watch('searchIds', () => {
                    if (scope.studentNetworkMapViewModel?.studentsMapLayer) {
                        scope.studentNetworkMapViewModel.byIds = scope.searchIds;
                        scope.studentNetworkMapViewModel.applyInitialSearch();
                    }
                });
            },
        };
    },
]);
