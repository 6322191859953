import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
// Based on https://snazzymaps.com/style/211231/cool-blue-gray-monochrome

/* eslint-disable */
angularModule.constant('STUDENT_NETWORK_MAP_STYLE', [
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#9ca8b9',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9ca8b9',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f7f7fa',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                saturation: '-30',
            },
            {
                lightness: '12',
            },
            {
                hue: '#0092ff',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
            {
                saturation: '-26',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                saturation: '-3',
            },
            {
                lightness: '-10',
            },
            {
                color: '#9ca8b9',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
            {
                saturation: '-100',
            },
            {
                lightness: '-5',
            },
            {
                color: '#847d76',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
            {
                color: '#5d6a7c',
            },
        ],
    },
]);

/* eslint-enable */
