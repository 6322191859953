
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.contact_form = window.Smartly.locales.modules.en.back_royal.student_network.contact_form || {};
window.Smartly.locales.modules.en.back_royal.student_network.contact_form = {...window.Smartly.locales.modules.en.back_royal.student_network.contact_form, ...{
              
    "contact": "Contact",

             
    "reply_to": "Reply To",
    "subject": "Subject",
    "message": "Message",

                   
    "reply_to_placeholder": "your.email@address.com",
    "subject_placeholder": "[{{brandName}} Network] Message from {{sendersName}}",
    "message_placeholder_mobile_safari": "Hi {{recipientsNickname}}, I wanted to reach out to introduce myself...",
    "message_placeholder_local": "Hi {{recipientsNickname}},\n\nI saw that you're located in my area and wanted to reach out and introduce myself as a fellow {{brandName}} student! I’d love to connect to...\n\nBest,\n{{sendersNickname}}",
    "message_placeholder_attrs": "Hi {{recipientsNickname}},\n\nI wanted to reach out after seeing your profile – it looks like we have a few things in common beyond being {{brandName}} students! I’d love to connect to...\n\nBest,\n{{sendersNickname}}",
    "message_placeholder_default": "Hi {{recipientsNickname}},\n\nYour profile looks interesting, and I wanted to reach out and introduce myself as a fellow {{brandName}} student! I’d love to connect to...\n\nBest,\n{{sendersNickname}}",

             
    "send_message": "Send Message",

               
    "message_sent": "Message Sent"
}
}
    