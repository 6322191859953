import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_network_recommended_event_list.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

/*
    NOTE: Even though the name of this directive seems to indicate that this list
    is only for "recommended" events, we do show non-recommended events in this
    directive based on the state of the eventsMapLayer, specifically the filters
    that the user interacts with in the UI.
*/
angularModule.directive('studentNetworkRecommendedEventList', [
    '$injector',
    function factory($injector) {
        const isMobileMixin = $injector.get('isMobileMixin');

        return {
            restrict: 'E',
            scope: {
                eventsMapLayer: '<',
            },
            templateUrl,

            link(scope, elem) {
                isMobileMixin.onLink(scope);

                // In the UI, the event list expands to fill the rest of the height of the window
                // underneath the filters section, but the height of the filters section can change
                // depending on whether or not the filters are open/closed and what filters are selected,
                // so we need to calculate the dynamic height value for the event list by first calculating
                // the outerHeight (margins included) of the filters section and then subtracting that value
                // from 100% of the available height for this directive's content.
                scope.getRecommendedEventListContainerHeightCalculation = () => {
                    const eventListFiltersHeight = elem
                        .find('#recommended-event-list-filters-container')
                        .outerHeight(true);
                    return `calc(100% - ${eventListFiltersHeight}px)`;
                };

                //--------------------
                // Methods called from UI
                //--------------------

                scope.returnToMapCallback = () => {
                    scope.eventsMapLayer.mobileState.showingRecommendedEventsList = false;
                };
            },
        };
    },
]);
