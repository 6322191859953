import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
/*
    StudentNetworkFilterSet is instantiated with a list of filters and then
    is responsible for loading up batches of profiles that match those
    filters whenever necessary, until no more are left.
*/
angularModule.factory('StudentNetworkFilterSet', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const CareerProfile = $injector.get('CareerProfile');

        const StudentNetworkFilterSet = SuperModel.subclass(function () {
            Object.defineProperty(this.prototype, 'any', {
                get() {
                    return this._initialResultsLoaded ? _.some(this.careerProfiles) : false;
                },
            });

            Object.defineProperty(this.prototype, 'searchingForMoreWhenEmpty', {
                get() {
                    return (!!this._loadingPromise || !this._initialResultsLoaded) && !this.any;
                },
            });

            Object.defineProperty(this.prototype, 'searchingForMore', {
                get() {
                    return !!this._loadingPromise;
                },
            });

            Object.defineProperty(this.prototype, 'noMoreAvailable', {
                get() {
                    return !!this._allDataLoaded;
                },
            });

            Object.defineProperty(this.prototype, 'noAdditionalStudents', {
                get() {
                    return this.noMoreAvailable && !this.any;
                },
                configurable: true,
            });

            return {
                initialize(filters, sort, options = {}, focusedCluster) {
                    this.sourceFilters = filters; // this is just used in specs.  Probably kind of a silly way to do it
                    this.filters = angular.copy(filters);
                    this.sort = sort || ['BEST_SEARCH_MATCH_FOR_HM'];
                    this.offset = options.offset || 0;
                    this.focusedCluster = focusedCluster;

                    // minLength acts as a threshold to help determine when an API call should be made to retrieve more profiles.
                    // Should the curent number of profiles dips below the value of minLength, more profiles will be retrieved, if necessary.
                    this.minLength = options.minLength || 5;

                    // lets the server know the max number of profiles it should return
                    this.serverLimit = options.serverLimit || 10;

                    this.careerProfiles = [];
                    this._initialResultsLoaded = false;
                },

                isEqual(filterSet) {
                    return (
                        _.isEqual(this.filters, filterSet.filters) &&
                        this.sort === filterSet.sort &&
                        _.isEqual(this.options, filterSet.options) &&
                        this.focusedCluster === filterSet.focusedCluster
                    );
                },

                cloneFilters() {
                    return angular.copy(this.filters);
                },

                // resets the offset value to 0 or increments/decrements the offset value using the listLimit based on
                // the action provided and then attempts to make an API call to retrieve the profiles if necessary
                getProfilesRelativeToOffset(action, listLimit) {
                    if (action === 'first') {
                        this.offset = 0;
                    } else if (action === 'previous') {
                        this.offset = _.max([this.offset - listLimit, 0]); // prevent offset from becoming negative
                    } else if (action === 'next') {
                        this.offset += listLimit;
                    } else {
                        throw new Error(`Unsupported pagination control action: ${action}`);
                    }

                    return this.ensureStudentProfilesPreloaded();
                },

                // Users of StudentNetworkFilterSet are expected to remove things from `careerProfiles`.
                // If `careerProfiles` gets shorter than the minLength, then StudentNetworkFilterSet will
                // make another request to make sure it has enough.
                ensureStudentProfilesPreloaded() {
                    const self = this;

                    if (self._loadingPromise) {
                        return self._loadingPromise;
                    }

                    if (self.careerProfiles.length - self.offset < self.minLength && !self._allDataLoaded) {
                        const maxTotalCount = 100;
                        self._loadingPromise = CareerProfile.index({
                            view: 'student_network_profiles',
                            filters: this._buildFiltersForApiRequest(),
                            limit: self.serverLimit,
                            max_total_count: maxTotalCount,
                            offset: self.careerProfiles.length,
                            'sort[]': self.sort,
                            direction: 'desc',
                        }).then(response => {
                            // We don't want to decrement the initialTotalCount since we load more results under
                            // the hood, so only cache total_count when loading the initial results
                            if (response.meta && !self._initialResultsLoaded) {
                                self.initialTotalCount = response.meta.total_count;
                                self.initialTotalCountIsMin = self.initialTotalCount === maxTotalCount;
                            }

                            self._initialResultsLoaded = true;
                            self._loadingPromise = null;
                            self.careerProfiles = self.careerProfiles.concat(response.result);
                            self._userIds = _.map(self.careerProfiles, 'user_id');

                            if (response.result.length < self.serverLimit) {
                                self._allDataLoaded = true;
                            }

                            // Calling ensureStudentProfilesPreloaded again after a request completes
                            // allows us to catch up if the offset has moved ahead while we were loading.
                            // The UI should prevent the user from banging on the next button anyhow, but
                            // if not, this makes sure we eventually load what we need to.
                            self.ensureStudentProfilesPreloaded();
                        });
                    }

                    return self._loadingPromise;
                },

                _buildFiltersForApiRequest() {
                    const overrides = {};

                    // if there's a focused student feature, restrict to points within it
                    if (this.focusedCluster) {
                        this.filters.within_geometry = this.focusedCluster.properties.cluster_hull;
                    }

                    const filters = _.extend({}, this.filters, overrides);
                    return filters;
                },
            };
        });

        return StudentNetworkFilterSet;
    },
]);
