import { useEffect, useState } from 'react';
import { useAngularContext } from 'AngularContext';

export default function useLoadStudentMarkers(group, filters, mapInfo, studentsMapLayer) {
    const markerWidth = 12;
    const [features, setFeatures] = useState();
    const $injector = useAngularContext();
    const StudentNetworkMap = $injector.get('StudentNetworkMap');

    useEffect(() => {
        if (!group || !mapInfo) {
            return;
        }
        const defaultFilters = {
            program_type: ['mba', 'emba'], // FIXME: Should this include emba_strategic_leadership and mba_leadership? (https://trello.com/c/0qkpPMus)
            only_local: true,
        };
        const { zoom, viewports } = mapInfo;
        const newfilters = {
            zoom,
            viewports,
            marker_width: markerWidth,
            ...defaultFilters,
            ...filters,
        };

        if (studentsMapLayer.loading) {
            return;
        }

        studentsMapLayer.loading += 1;

        StudentNetworkMap.index({
            filters: newfilters,
        }).then(response => {
            studentsMapLayer.loading -= 1;
            const data = response.result[0].features;
            setFeatures(data);
        });
    }, [StudentNetworkMap, group, filters, mapInfo, studentsMapLayer]);

    return features;
}
