import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import * as userAgentHelper from 'userAgentHelper';
import template from 'StudentNetwork/angularModule/views/student_network_event_details.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkEventDetails', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $window = $injector.get('$window');
        const RouteAssetLoader = $injector.get('Navigation.RouteAssetLoader');
        const isMobileMixin = $injector.get('isMobileMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');

        return {
            restrict: 'E',
            scope: {
                event: '<',

                // By default, this directive will expand to fill the contents of its containing element
                // and overflow past the bottom if the directive content is tall enough. To ensure that
                // the event details content becomes scrollable if the directive content is taller than
                // the containing element, you must supply the `containerSelector` isolate scope attribute
                // and set its value to CSS selector for the containing element of this directive. Doing
                // so will expand this directive's content to fill the containing element and make the
                // event details section scrollable if it overflows past the RSVP button at the bottom.
                containerSelector: '<?',
            },
            templateUrl,
            link(scope, elem) {
                const ConfigFactory = $injector.get('ConfigFactory');
                scope.chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();
                isMobileMixin.onLink(scope);

                // We hide the app header on mobile on this screen
                scope.$watch('isMobile', () => {
                    AppHeaderViewModel.toggleVisibility(!scope.isMobile);
                });

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.timezone = scope.chinaRegionMode ? 'Asia/Shanghai' : scope.currentUser.timezone;

                // Using RouteAssetLoader here instead of in route-resolvers because we don't
                // really want to block the whole page until the google api loads.  We really
                // just want to block the map
                if (!scope.chinaRegionMode) {
                    RouteAssetLoader.loadGooglePlacesDependencies().then(() => {
                        scope.googleMapsLoaded = true;
                    });
                }

                //-------------------------------------
                // Scrollable Event Details Section
                //-------------------------------------

                /*
                        This shinnanigans is kind of annoying, but it's necessary to ensure that the UI responds
                        appropriately when the window is resized. At the bottom of the directive, there's a button
                        that the user can click to RSVP for the event. We'd like this button to remain visible in
                        the UI below the event details content, fixed to the bottom of the container, regardless of
                        the window size, and have the event details content become scrollable above the RSVP button
                        if the details overflow past it. Even if the window is a size that makes all of the event
                        details content visible, we'd still like the RSVP button to remain fixed at the bottom of
                        the container, below the event details content, leaving any excess whitespace between the
                        details content and the RSVP button, if there is any. In other words, we'd like the RSVP
                        button to be like a "sticky" element to the bottom of the event details content. To do
                        this, we calculate the appropriate `height` value for the event details content to be
                        `containerHeight - headerHeight - footerHeight`.

                        NOTE: On scope for easier testing.
                    */
                scope.ensureDetailsContentScrollable = () => {
                    if (!scope.containerSelector) {
                        return;
                    }

                    // collect the heights of the relevant elements
                    const headerHeight = elem.find('header').innerHeight();
                    const footerHeight = elem.find('footer').innerHeight();

                    // calculate the desired `height` value (see comment above) and set the `height` CSS attribute
                    const containerHeight = $(scope.containerSelector).height();
                    const calculatedEventDetailsContentHeight = containerHeight - headerHeight - footerHeight;
                    elem.find('section.details').css('height', `${calculatedEventDetailsContentHeight}px`);
                };

                scope.onEventDetailsImageLoad = () => {
                    // The `eventDetailsImageLoaded` flag is used to control the visibility of the details section below
                    // the event details image. When the flag gets flipped to true, we render the event details and we
                    // need to call `ensureDetailsContentScrollable`, but AFTER the event details content has rendered,
                    // so we need to make sure that we call `safeApply` BEFORE `ensureDetailsContentScrollable`.
                    scope.eventDetailsImageLoaded = true;
                    safeApply(scope);
                    scope.ensureDetailsContentScrollable();
                };

                // `imageLoadError` is set by the on-image-load directive, which is used
                // on the event image in the header
                scope.$watch('imageLoadError', () => {
                    if (scope.imageLoadError) {
                        scope.onEventDetailsImageLoad();
                    }
                });

                scope.$watch('event.imageSrc', src => {
                    if (!src) {
                        scope.imageLoadError = true;
                        scope.eventDetailsImageLoaded = true;
                    } else {
                        scope.imageLoadError = false;
                        scope.eventDetailsImageLoaded = false;
                    }
                });

                $(window).on(`resize.${scope.$id}`, () => {
                    scope.ensureDetailsContentScrollable();
                });

                scope.$on('$destroy', () => {
                    $(window).off(`resize.${scope.$id}`);
                    AppHeaderViewModel.toggleVisibility(true);
                });

                //----------------------------
                // Scope Methods & Helpers
                //----------------------------

                // The `addToCalendarWidget` directive listens for the `closeCalendarOptions` event and
                // ensures that the options dropdown is closed when it sees this event has been triggered.
                scope.closeCalendarOptions = () => {
                    scope.$broadcast('closeCalendarOptions');
                };

                scope.getDirectionsToEvent = event => {
                    if (!event.coordinates) {
                        ErrorLogService.notify('Student Network Event missing coordinates', null, {
                            event_id: event.id,
                        });
                        return;
                    }

                    // See https://gist.github.com/danharper/844382901f7a7b73b90a
                    // I'm setting a label because otherwise, in Android with Google Maps,
                    // the label is "Unnamed Location" if we only send the coordinates. So I decided to
                    // start collecting name from the place lookup, which will be the name of the venue,
                    // and falling back to the formatted_address if the place_id does not have a name.
                    // FWIW, showing the address as the label seems to be the default behavior in Apple Maps.
                    //
                    // NOTE: There appears to be a bug with the label at the moment.
                    // See https://stackoverflow.com/q/55078219/1747491 and the linked issue on Android's
                    // issue tracker
                    const [lat, lng] = event.coordinates;
                    const latLngString = `${lat},${lng}`;
                    const label = $window.encodeURI(event.place_details.name || event.place_details.formatted_address);
                    const cordovaQueryString = `?q=${latLngString}(${label})`;

                    let url;
                    if (
                        userAgentHelper.isiOSoriPadOSDevice() &&
                        ($window.CORDOVA || userAgentHelper.isMobileSafari())
                    ) {
                        url = `maps://${cordovaQueryString}`;
                    } else if (userAgentHelper.isAndroidDevice() && ($window.CORDOVA || userAgentHelper.isChrome())) {
                        // See https://developer.android.com/guide/components/intents-common.html#Maps
                        url = `geo:${latLngString}${cordovaQueryString}`;
                    } else if (scope.chinaRegionMode) {
                        // See https://lbs.amap.com/api/uri-api/guide/travel/route
                        url = `http://uri.amap.com/navigation?to=${lng},${lat},${label}&src=Quantic`;
                    } else {
                        // See https://developers.google.com/maps/documentation/urls/guide#directions-action
                        // See https://stackoverflow.com/a/22330384/1747491
                        // Note: The docs say destination is required if using destination_place_id
                        const encodedFormattedAddress = $window.encodeURI(event.place_details.formatted_address);
                        url = `https://www.google.com/maps/dir/?api=1&origin=My+Location&destination_place_id=${event.place_id}&destination=${encodedFormattedAddress}`;
                    }

                    // loadUrl will handle setting _blank to _system if we're in cordova
                    NavigationHelperMixin.loadUrl(url, '_blank');
                };

                scope.rsvpForEvent = () => {
                    NavigationHelperMixin.loadUrl(scope.event.external_rsvp_url, '_blank');
                };
            },
        };
    },
]);
