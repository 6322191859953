import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import 'FrontRoyalUiBootstrap/popover';
import template from 'StudentNetwork/angularModule/views/event_marker.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('eventMarker', [
    '$injector',

    function factory($injector) {
        const GoogleOverlayView = $injector.get('GoogleOverlayView');
        const $window = $injector.get('$window');
        const NgMap = $injector.get('NgMap');
        const StudentNetworkEvent = $injector.get('StudentNetworkEvent');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                feature: '<',
                eventsMapLayer: '<',
            },
            link(scope, elem) {
                //--------------------
                // Initialization and Drawing Lifecycle
                //--------------------

                scope.icon = StudentNetworkEvent.EVENT_TYPE_CONFIGS_MAP[scope.feature.event_type].icon;

                let googleOverlayView;
                let map;

                // When this element is instantiated, we create a new overlay on the map with its view HTML
                NgMap.getMap({
                    id: 'student-network-map',
                }).then(theMap => {
                    // It's possible that the user navigated away while getMap was running
                    if (scope.$$destroyed) {
                        return;
                    }
                    map = theMap;

                    // FIXME: determine z-index based on some criteria
                    // $(elem).css('z-index', scope.style['z-index']);

                    // FIXME: determine appropriate styling (maybe bind the view's class to the type attribute or something)

                    // create an overlay to place on the Google Map (it will use our view for its content)
                    // Hat tip to https://www.reonomy.com/blog/post/creating-custom-google-map-markers-with-angular

                    // Users who have limited access to student network events see anonymized data. The server ensures
                    // that the appropriate data is included in the JSON that's returned to the client based on the user's
                    // access level, so in this directive, if there are anonymized place details present, the user has
                    // restricted access; however, if the anonymized details are undefined, the user has full access.
                    const placeDetails = scope.feature.place_details_anonymized || scope.feature.place_details;
                    const latlng = new $window.google.maps.LatLng(placeDetails.lat, placeDetails.lng);
                    googleOverlayView = new GoogleOverlayView(elem, latlng);
                    googleOverlayView.setMap(map);
                });

                // Remove the overlay from the map when this directive is destroyed
                scope.$on('$destroy', () => {
                    googleOverlayView.setMap(null);
                });
            },
        };
    },
]);
