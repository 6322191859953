import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_network.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { react2Angular } from 'FrontRoyalReact2Angular';
import NetworkAmap from 'StudentNetwork/react/NetworkAmap';

angularModule.component('networkAmap', react2Angular(NetworkAmap, ['eventId', 'showEvents'], '', false));

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetwork', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const isMobileMixin = $injector.get('isMobileMixin');
        const SiteMetadata = $injector.get('SiteMetadata');
        const TranslationHelper = $injector.get('TranslationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                eventId: '@', // optional query parameter coming from $routeProvider
                showEvents: '@', // also optional query parameter
            },
            link(scope) {
                const ConfigFactory = $injector.get('ConfigFactory');
                scope.chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();

                //--------------------
                // Page Setup
                //--------------------

                NavigationHelperMixin.onLink(scope);
                isMobileMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'showWelcomeModal', {
                    get() {
                        return (
                            scope.currentUser?.hasAccessToFullStudentNetworkProfiles &&
                            !scope.currentUser.has_seen_student_network
                        );
                    },
                });

                Object.defineProperty(scope, 'missingRequiredStudentNetworkEmail', {
                    get() {
                        return scope.currentUser?.missingRequiredStudentNetworkEmail;
                    },
                });

                // Setup localization keys
                const translationHelper = new TranslationHelper('student_network.student_network');

                AppHeaderViewModel.setTitleHTML(translationHelper.get('network_title'));
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;
                $injector.get('scrollHelper').scrollToTop();

                // Default title
                SiteMetadata.updateHeaderMetadata();

                scope.ensureWelcomeFlagSaved = () => {
                    if (scope.currentUser.has_seen_student_network) {
                        return;
                    }
                    scope.currentUser.has_seen_student_network = true;
                    if (!scope.currentUser.ghostMode) {
                        scope.currentUser.save();
                    }
                };
            },
        };
    },
]);
