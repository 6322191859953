
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.field_options = window.Smartly.locales.modules.en.back_royal.student_network.field_options || {};
window.Smartly.locales.modules.en.back_royal.student_network.field_options = {...window.Smartly.locales.modules.en.back_royal.student_network.field_options, ...{
                           
    "co_founder": "Co-founder",
    "study_partner": "Study partner",
    "collaborators_talent": "Collaborators & talent to hire",
    "funding": "Funding",
    "ideas_inspiration": "Ideas & inspiration",
    "job_opportunities": "Job opportunities",
    "mentorship": "Mentorship",
    "new_friends": "New friends",

              
    "full": "Standard (Recommended)",
    "anonymous": "Anonymous",
    "hidden": "Hidden",

                            
    "popular_interests": "Popular Interests",
    "travel": "Travel",
    "finance": "Finance",
    "entrepreneurship": "Entrepreneurship",
    "art": "Art",
    "education": "Education",
    "marketing": "Marketing",
    "health_care": "Health Care",
    "nonprofit": "Nonprofit",
    "sports": "Sports",
    "technology": "Technology",
    "data_science": "Data Science",
    "blockchain": "Blockchain",
    "product_management": "Product Management",
    "design": "Design",
    "sales": "Sales",
    "leadership": "Leadership",
    "mixology": "Mixology",
    "ai": "AI"
}
}
    