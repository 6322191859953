import { useContext, useEffect } from 'react';
import { useAngularContext } from 'AngularContext';
import AMapContext from './AMapContext';

export default function StudentCluster(props) {
    const { group, feature, setAdvancedSearchModel } = props;
    const AMap = useContext(AMapContext);
    const $injector = useAngularContext();
    const StudentClusterStyle = $injector.get('StudentClusterStyle');

    useEffect(() => {
        const style = StudentClusterStyle.getStyleForFeature(feature);

        const content = `<div class="student-cluster" data-id=${feature.id}
                style="border-width: ${style['border-width']}px; width: ${style.width}px;  height: ${style.height}px; z-index: ${style['z-index']}">
                <span class="count">${feature.properties.count}</span>
            </div>`;
        const markerOpt = {
            position: feature.geometry.coordinates,
            content,
            anchor: 'center',
            zIndex: style['z-index'],
            offset: new AMap.Pixel(0, 0),
        };
        const marker = new AMap.Marker(markerOpt);
        marker.on('click', () => {
            setAdvancedSearchModel(prev => ({
                ...prev,
                focusedFeature: _.isEqual(feature, prev.focusedFeature) ? null : feature,
            }));
        });
        group.addOverlay(marker);

        return () => {
            group.removeOverlay(marker);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [StudentClusterStyle, AMap, group, setAdvancedSearchModel]);

    return null;
}
