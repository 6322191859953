
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.partial_access_modal = window.Smartly.locales.modules.en.back_royal.student_network.partial_access_modal || {};
window.Smartly.locales.modules.en.back_royal.student_network.partial_access_modal = {...window.Smartly.locales.modules.en.back_royal.student_network.partial_access_modal, ...{
    "explain": "The {{brandName}} Network is an exclusive global community for students and alumni.",
    "instructions": "Use the map and filters to explore this feature-limited preview.",
    "deadline": "Next application deadline: <br /><span>{{date}}</span>",
    "apply_now": "Apply Now",
    "explain_react": "The {brandName} Network is an exclusive global community for students and alumni.",
    "deadline_react": "Next application deadline: <0/><1>{date}</1>"
}
}
    