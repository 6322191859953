import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/network_map_filter_box_students_tab.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { studentNetworkQuickFilterConfigs } from 'StudentNetwork/StudentNetworkQuickFilterConfigs';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('networkMapFilterBoxStudentsTab', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                studentsMapLayer: '<',
            },
            link(scope) {
                scope.quickFilterConfigs = studentNetworkQuickFilterConfigs(scope.studentsMapLayer);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // shown if "Students & Alumni" top-level filter selected
                scope.programTypeFilters =
                    scope.currentUser?.relevantCohort?.studentNetworkQuickFilterConfigKeys?.map(
                        k => scope.quickFilterConfigs[k],
                    ) ?? [];

                //--------------------
                // Watches
                //--------------------

                scope.getSelected = (programTypes, classFilter) => {
                    let selected = false;

                    if (programTypes === 'mine') {
                        selected = classFilter === 'mine' || (classFilter?.length === 1 && classFilter?.[0] === 'mine');
                    }

                    if (programTypes === 'all') {
                        selected = programTypes === classFilter;
                    }

                    if (Array.isArray(programTypes) && Array.isArray(classFilter)) {
                        selected = classFilter.every(filter => programTypes.includes(filter));
                    }

                    return selected;
                };

                // on the scope to enable testing
                scope.applyFilters = value => {
                    scope.studentsMapLayer.applyFilters(value);
                };
                const _applyFiltersDebounced = _.debounce(scope.applyFilters, 250);
                scope.$watch('studentsMapLayer.filters', _applyFiltersDebounced, true);

                //--------------------
                // Advanced Searching / Filtering
                //--------------------

                scope.openAdvancedSearch = () => {
                    scope.studentsMapLayer.applyAdvancedFilters();
                    scope.studentsMapLayer.inAdvancedSearchMode = true;
                    if (scope.isMobile) {
                        scope.studentsMapLayer.mobileState.expanded = true;
                    }
                };
            },
        };
    },
]);
