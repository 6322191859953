import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/network_map_filter_box.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('networkMapFilterBox', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                studentNetworkMapViewModel: '<',
            },
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // watch for changes to accepted application to update the "my class" cohort ID
                scope.$watch('currentUser.cohortForActiveProgramInclusion', () => {
                    scope.studentNetworkMapViewModel.updateMyClassCohortId(scope.currentUser);
                });
            },
        };
    },
]);
