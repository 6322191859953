/* eslint-disable no-nested-ternary */
import cacheAngularTemplate from 'cacheAngularTemplate';

import studentsSvg from 'vectors/students.svg';
import angularModule from '../student_network_module';
import template from '../../views/network_map_filter_box_advanced_student_search.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('networkMapFilterBoxAdvancedStudentSearch', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const isMobileMixin = $injector.get('isMobileMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const Cohort = $injector.get('Cohort');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                studentsMapLayer: '<',
            },
            link(scope) {
                scope.studentsSvg = studentsSvg;

                //--------------------
                // Setup and Initialization
                // -------------------

                isMobileMixin.onLink(scope);

                // proxy is used to track whether the student profile list is showing profiles or not
                scope.proxy = {};

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'totalCount', {
                    get() {
                        const focusedClusterCount =
                            scope.studentsMapLayer.focusedClusterFeature &&
                            scope.studentsMapLayer.focusedClusterFeature.properties.count;
                        const plus = scope.studentsMapLayer.studentProfileFilterSet.initialTotalCountIsMin ? '+' : '';
                        return (
                            focusedClusterCount ||
                            (scope.studentsMapLayer.studentProfileFilterSet.any
                                ? scope.studentsMapLayer.studentProfileFilterSet.initialTotalCount + plus
                                : 0)
                        );
                    },
                });

                const translationHelper = new TranslationHelper('student_network.network_map_filter_box');
                const valarProgramTypeOptions = scope.studentsMapLayer.valarDegreeProgramTypeFilterValues
                    .map(programType => ({
                        value: programType,
                        label: Cohort.shortProgramTitle(programType),
                        selectedLabel: Cohort.networkProgramTitle(programType),
                    }))
                    .sort(({ label: labelA }, { label: labelB }) => (labelA < labelB ? -1 : labelA > labelB ? 1 : 0));

                const quanticProgramTypeOptions = scope.studentsMapLayer.quanticDegreeProgramTypeFilterValues
                    .map(programType => ({
                        value: programType,
                        label: Cohort.shortProgramTitle(programType),
                        selectedLabel: Cohort.networkProgramTitle(programType),
                    }))
                    .sort(({ label: labelA }, { label: labelB }) => (labelA < labelB ? -1 : labelA > labelB ? 1 : 0));

                const execEdProgramTypeOptions = scope.studentsMapLayer.execEdProgramTypeFilterValues
                    .map(programType => ({
                        value: programType,
                        label: Cohort.shortProgramTitle(programType),
                        selectedLabel: Cohort.networkProgramTitle(programType),
                    }))
                    .sort(({ label: labelA }, { label: labelB }) => (labelA < labelB ? -1 : labelA > labelB ? 1 : 0));

                scope.classFilterOptions = [
                    [translationHelper.get('quanticDegreePrograms'), quanticProgramTypeOptions],
                    [translationHelper.get('valarDegreePrograms'), valarProgramTypeOptions],
                    [translationHelper.get('execEdPrograms'), execEdProgramTypeOptions],
                ];

                scope.graduationStatusFilterOptions = [
                    {
                        value: null,
                        label: translationHelper.get('graduation_status'),
                    },
                    {
                        value: true,
                        label: translationHelper.get('graduated'),
                    },
                    {
                        value: false,
                        label: translationHelper.get('not_graduated'),
                    },
                ];

                //--------------------
                // Watches
                // -------------------

                // Watch for this event and update the advanced search
                scope.$on('addInterestToFilters', (_event, interest) => {
                    scope.studentsMapLayer.addInterestToFilters(interest);
                });

                scope.$watch('studentsMapLayer.myClassCohortId', cohortId => {
                    if (cohortId) {
                        // add it to advanced filter options but only once. This gets called
                        // with every ping because of the watch it's on
                        if (
                            !_.find(scope.classFilterOptions, {
                                value: 'mine',
                            })
                        ) {
                            scope.classFilterOptions.splice(1, 0, {
                                value: 'mine',
                                label: translationHelper.get('mine'),
                            });
                        }
                    }
                });

                function applyFilters(value) {
                    scope.studentsMapLayer.applyFilters(value);
                }
                const _applyFiltersDebounced = _.debounce(applyFilters, 250);
                scope.$watch('studentsMapLayer.filters', _applyFiltersDebounced, true);

                //--------------------
                // Methods called from the UI
                // -------------------

                scope.removeFocusedCluster = () => {
                    scope.studentsMapLayer.focusedClusterFeature = null;
                };

                scope.applyAdvancedFilters = () => {
                    scope.studentsMapLayer.applyAdvancedFilters();
                };

                scope.returnToMapCallback = () => {
                    scope.studentsMapLayer.inAdvancedSearchMode = false;
                };
            },
        };
    },
]);
