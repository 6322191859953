
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list = window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list = {...window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list, ...{
    "select_an_event": "Select an Event",
    "no_events_found": "No Events Found",
    "date_tbd_label": "Date TBD"
}
}
    