import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import 'FrontRoyalUiBootstrap/popover';
import template from 'StudentNetwork/angularModule/views/student_network_event_ambiguous_popover.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkEventAmbiguousPopover', [
    '$injector',
    function factory($injector) {
        const isMobileMixin = $injector.get('isMobileMixin');
        const $window = $injector.get('$window');
        const $timeout = $injector.get('$timeout');

        return {
            restrict: 'E',
            scope: {
                feature: '<',
                events: '<',
                eventsMapLayer: '<',
            },
            templateUrl,

            link(scope, elem) {
                isMobileMixin.onLink(scope);

                // These popovers can be destroyed manually, or by an 'outsideClick' event.
                // When a popover is destroyed by an 'outsideClick' event, we need to be sure
                // to run this callback, which will handle the conditional re-enabling of gesture
                // controls on the map.
                scope.$on('$destroy', () => {
                    $(window).off(`resize.${scope.$id}`);
                    scope.eventsMapLayer.handleGestureControlsOnPopoverStateChange(scope.feature);
                });

                // In some mobile browsers, address bars and action bars along the bottom of the
                // browser interface are not excluded from viewport unit calculations (vh and vw).
                // This makes it exceedingly difficult to use 100vh or 100vw in conjunction with
                // CSS calculations inside these browsers.
                // See also: https://gist.github.com/claus/622a938d21d80f367251dc2eaaa1b2a9#quirks
                //
                // Additionally, there are scenarios in which we display a student-network-event-list
                // outside of the student-network DOM heirarchy, and append it to the body in a popover.
                // This complicates using 100% inside of height/width calculations.
                //
                // The only way to ensure that we have a properly sized and scrollable list element
                // inside these quirky mobile browsers is to dynamically calculate the height.
                scope.ensureEventListContainerScrollable = () => {
                    if (scope.isMobile && !$window.CORDOVA) {
                        const scrollableElement = elem.find('.event-list-container');

                        // window.innerHeight respects browser interfaces
                        const windowInnerHeight = $window.innerHeight;
                        const listHeaderHeight = elem.find('header').outerHeight(true);
                        const appMenuMobileHeight = $('.app-menu-mobile').outerHeight(true);

                        // There are top/bottom offsets on the parent popover, which are difficult to get to
                        // so hard coding this for now.
                        const paddingHeight = 22;

                        let height = windowInnerHeight - appMenuMobileHeight - listHeaderHeight - paddingHeight;

                        // The max-height of the event-list-container is 421px. There's no need to
                        // set the height to something greater than that. There's no practicul issue
                        // being solved here, but it avoids confusion for the next dev. Seeing
                        // `max-height: 421px;` and `height: 701px;` in dev tools is needlessly confusing.
                        height = Math.min(421, height);

                        scrollableElement.css('height', `${height}px`);
                    }
                };

                // Wait a beat and then recalculate the max-height of the list
                $timeout(() => {
                    scope.ensureEventListContainerScrollable();
                });

                $(window).on(`resize.${scope.$id}`, () => {
                    scope.ensureEventListContainerScrollable();
                });
            },
        };
    },
]);
