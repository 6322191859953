import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/network_map_filter_box_mini.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('networkMapFilterBoxMini', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                studentNetworkMapViewModel: '<',
            },
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //--------------------
                // Tabs
                //--------------------

                scope.setTab = value => {
                    if (value === 'students') {
                        scope.studentNetworkMapViewModel.setActiveMapLayer(
                            scope.studentNetworkMapViewModel.studentsMapLayer,
                        );
                    } else if (value === 'events') {
                        scope.studentNetworkMapViewModel.setActiveMapLayer(
                            scope.studentNetworkMapViewModel.eventsMapLayer,
                        );
                    } else {
                        throw new Error('Unsupported tab name');
                    }
                };
            },
        };
    },
]);
