import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_network_event_list.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkEventList', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const TranslationHelper = $injector.get('TranslationHelper');
        const safeDigest = $injector.get('safeDigest');

        return {
            restrict: 'E',
            scope: {
                events: '<',
                eventsMapLayer: '<',
                listContainerClasses: '<?',
                listItemClasses: '<?',
                reverseOrder: '<?',
            },
            templateUrl,

            link(scope) {
                const dateTbdLabel = new TranslationHelper('student_network.student_network_event_list').get(
                    'date_tbd_label',
                );

                scope.reverseOrder = scope.reverseOrder || false;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // observe last entry in lazy loaded list
                // if last entry is in viewport, increase limit and update observer
                const observerCallback = (entries, obs) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting && scope.limit < scope.events.length) {
                            scope.limit += 10;
                            safeDigest(scope);
                            obs.unobserve(entry.target);
                            obs.observe(document.querySelector('.list-container > :last-child'));
                        }
                    });
                };

                const options = { rootMargin: '0px 0px 300px 0px' };

                scope.getDateLabelForEvent = event =>
                    event.date_tbd
                        ? dateTbdLabel
                        : event.formattedRelativeStartDayOrDateTime('dddd, MMMM D, YYYY', scope.currentUser?.timezone);

                scope.shouldShowDateSeparator = (event, index) => {
                    const previousEvent = scope.sortedEvents[index - 1];

                    if (!previousEvent) {
                        return true;
                    }

                    if (event.date_tbd && previousEvent.date_tbd) {
                        return false;
                    }

                    return scope.getDateLabelForEvent(event) !== scope.getDateLabelForEvent(previousEvent);
                };

                // All events are sorted by start_time but date_tbd events are broken out first
                // and sorted in their own subsection
                scope.$watch('events', () => {
                    const scheduledEvents = _.sortBy(
                        scope.events?.filter(e => !e.date_tbd),
                        e => e.start_time,
                    );
                    const dateTbdEvents = _.sortBy(
                        scope.events?.filter(e => e.date_tbd),
                        e => e.start_time || Number.MAX_SAFE_INTEGER,
                    );
                    scope.sortedEvents = scope.reverseOrder
                        ? [...scheduledEvents, ...dateTbdEvents].reverse()
                        : [...scheduledEvents, ...dateTbdEvents];
                });

                scope.limit = 20;

                const observer = new IntersectionObserver(observerCallback, options);

                // setup observer on initial last-child
                scope.$watch('sortedEvents', () => {
                    setTimeout(() => {
                        const element = document.querySelector('.list-container > :last-child');
                        if (element) {
                            observer.observe(element);
                        }
                    }, 0);
                });
            },
        };
    },
]);
