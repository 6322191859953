import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_cluster.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupStyleHelpers } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule
    .service('StudentClusterStyle', [
        function factory() {
            //---------------------
            // Constants for scaling of borders, width, etc.
            //---------------------
            const markerWidth = 20;

            const minScaleSize = 12;
            const maxScaleSize = markerWidth * 2;
            const minStrokeSize = 2;
            const maxStrokeSize = 4;
            const minCount = 10;
            const maxCount = 300;

            //---------------------
            // Helper functions
            //---------------------

            // bounds the domain of a variable and scales its range to be [0,1] inclusive
            function rescale(x, minCutoff, maxCutoff) {
                return Math.min(1, Math.max(0, x - minCutoff) / (maxCutoff - minCutoff));
            }

            // linear interpolation between min and max, given t
            function lerp(min, max, t, easingFunction) {
                t = easingFunction ? easingFunction(t) : t;
                return t * max + (1 - t) * min;
            }

            //---------------------
            // API
            //---------------------

            this.getStyleForFeature = _.memoize(
                feature => {
                    const count = feature.properties.count;
                    const t = rescale(count, minCount, maxCount);
                    const scale = Math.round(lerp(minScaleSize, maxScaleSize, t, k => Math.sin((Math.PI / 2) * k)));
                    const strokeWidth = Math.round(lerp(minStrokeSize, maxStrokeSize, t));

                    return {
                        'border-width': strokeWidth,
                        width: 2 * scale,
                        height: 2 * scale,
                        'z-index': Math.round(lerp(100, 1, rescale(count, 1, 100))), // put smaller markers on top of larger markers
                    };
                },
                feature => feature.properties.count,
            ); // count is enough to uniquely cache the result of the above function
        },
    ])
    .directive('studentCluster', [
        '$injector',

        function factory($injector) {
            const GoogleOverlayView = $injector.get('GoogleOverlayView');
            const StudentClusterStyle = $injector.get('StudentClusterStyle');
            const $window = $injector.get('$window');
            const NgMap = $injector.get('NgMap');
            const isMobileMixin = $injector.get('isMobileMixin');
            const $rootScope = $injector.get('$rootScope');

            return {
                restrict: 'E',
                templateUrl,
                scope: {
                    feature: '<',
                    studentsMapLayer: '<',
                },
                link(scope, elem) {
                    setupStyleHelpers($injector, scope);

                    // Referenced inside of AppBrandMixin.
                    Object.defineProperty(scope, 'currentUser', {
                        get() {
                            return $rootScope.currentUser;
                        },
                    });

                    isMobileMixin.onLink(scope);

                    //--------------------
                    // Initialization and Drawing Lifecycle
                    //--------------------

                    let googleOverlayView;

                    let map;
                    let focusedClusterWatcher;

                    // When this element is instantiated, we create a new overlay on the map with its view HTML
                    NgMap.getMap({
                        id: 'student-network-map',
                    }).then(theMap => {
                        // It's possible that the user navigated away while getMap was running
                        if (scope.$$destroyed) {
                            return;
                        }
                        map = theMap;

                        // style ourselves based on the number of people at this cluster
                        scope.style = StudentClusterStyle.getStyleForFeature(scope.feature);
                        $(elem).css('z-index', scope.style['z-index']);

                        // create an overlay to place on the Google Map (it will use our view for its content)
                        // Hat tip to https://www.reonomy.com/blog/post/creating-custom-google-map-markers-with-angular
                        const latlng = new $window.google.maps.LatLng(
                            scope.feature.geometry.coordinates[1],
                            scope.feature.geometry.coordinates[0],
                        );
                        googleOverlayView = new GoogleOverlayView(elem, latlng);
                        googleOverlayView.setMap(map);

                        scope.$watch('studentsMapLayer.focusedClusterFeature', onFocusedClusterChange);
                    });

                    // Remove the overlay from the map when this directive is destroyed
                    scope.$on('$destroy', () => {
                        googleOverlayView.setMap(null);

                        // if we're focused, clear our cluster
                        if (focusedClusterWatcher) {
                            focusedClusterWatcher();
                        }
                    });

                    //--------------------
                    // Helper Methods
                    //--------------------

                    // from https://stackoverflow.com/questions/10656743/how-to-offset-the-center-point-in-google-maps-api-v3
                    // latlng is the apparent centre-point
                    // offsetx is the distance you want that point to move to the right, in pixels
                    // offsety is the distance you want that point to move upwards, in pixels
                    // Note: offset can be negative; offsetx and offsety are both optional
                    function panToWithOffset(latlng, offsetx, offsety) {
                        const scale = 2 ** map.getZoom();

                        const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
                        const pixelOffset = new $window.google.maps.Point(offsetx / scale || 0, offsety / scale || 0);

                        const worldCoordinateNewCenter = new $window.google.maps.Point(
                            worldCoordinateCenter.x - pixelOffset.x,
                            worldCoordinateCenter.y + pixelOffset.y,
                        );

                        const newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

                        map.panTo(newCenter);
                    }

                    //--------------------
                    // Cluster Interactions
                    //--------------------

                    function onFocusedClusterChange() {
                        scope.focused = scope.studentsMapLayer.focusedClusterFeature === scope.feature;
                        const disabled = !scope.focused && !!scope.studentsMapLayer.focusedClusterFeature;

                        $(elem).toggleClass('focused', scope.focused);
                        $(elem).toggleClass('disabled', disabled);
                    }

                    scope.onClusterClick = () => {
                        if (scope.focused) {
                            scope.studentsMapLayer.focusedClusterFeature = undefined;
                            return;
                        }

                        // pan to show the selected item, leaving space for the sidebar on the left if we're opening it for the first time --
                        // don't do this in a < `sm` mobile breakpoint though, since this can be jarring and leave the user lost when exiting menu
                        if (!scope.studentsMapLayer.focusedClusterFeature && !scope.isMobile) {
                            const center = new $window.google.maps.LatLng(
                                scope.feature.geometry.coordinates[1],
                                scope.feature.geometry.coordinates[0],
                            );
                            panToWithOffset(center, 450, 0);
                        }

                        // toggle the list of visible candidates
                        scope.studentsMapLayer.focusedClusterFeature = scope.feature;
                    };
                },
            };
        },
    ]);
