import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';

angularModule.factory('StudentNetworkMessage', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('student_network_messages');
            this.alias('StudentNetworkMessage');

            return {};
        });
    },
]);
