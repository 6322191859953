
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network = window.Smartly.locales.modules.en.back_royal.student_network.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network = {...window.Smartly.locales.modules.en.back_royal.student_network.student_network, ...{
    "network_title": "NETWORK",

               
    "what_im_looking_for": "What I'm Looking For",
    "looking_for_desc": "Looking for something specific from the network? Select all that apply.",
    "interests": "My Interests",
    "interests_desc": "Find other students who share your interests, skills, or passions. Add up to 12 below.",
    "add_an_interest": "Add an interest",
    "privacy_settings": "Privacy Settings",
    "privacy_desc": "Select how you want your profile to look to fellow students.",
    "select_one": "Select One",
    "save_network_settings": "Save Network Settings",
    "saved_settings_success": "Settings were successfully saved.",
    "your_profile_will_be_visible": "Your profile will be visible to fellow students.<br class=\"hidden-sm\">Prospective students will see anonymous profiles only.",
    "preferred_email": "Preferred Email",
    "preferred_email_desc": "Supply an email address for interested parties to contact you.",
    "preferred_email_placeholder": "Email Address",
    "not_yet_available": "Network map is not yet<br /> available in your region."
}
}
    