
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.onboarding_dialog_modal = window.Smartly.locales.modules.en.back_royal.student_network.onboarding_dialog_modal || {};
window.Smartly.locales.modules.en.back_royal.student_network.onboarding_dialog_modal = {...window.Smartly.locales.modules.en.back_royal.student_network.onboarding_dialog_modal, ...{
               
    "step_one_title": "Welcome to the Network",
    "step_one_message": "Discover {{brandName}}'s exclusive global network. Use the map and filters to explore.",

               
    "step_two_title": "Navigate the Community",
    "step_two_message": "Circles represent clusters of students and alumni. Click to view profiles in that region.",

                 
    "step_three_title": "Connect with Confidence",
    "step_three_message": "Make meaningful connections by messaging classmates.",

                
    "step_four_title": "Discover Inspiring Events",
    "step_four_message": "Browse our exclusive, live events, <br>hosted online and around the world.",

                       
    "anonymous": "anonymous",
    "hidden": "hidden",

                 
    "activation_title": "Activate your Profile",
    "activation_message": "Your student network profile is currently set to <b>{{setting}}</b>. Would you like to make your profile visible to your peers?",
    "change_setting_smallprint": "You can change this preference at any time in <a ng-click=\"loadRoute('/settings/my-profile?page={{pageNumber}}')\">network settings</a>.",

            
    "email_title": "Preferred Contact Email",
    "email_message": "Just one more thing – please confirm the email address you'd like to receive messages from students and alumni!",
    "email_address": "Email Address",

              
    "next": "Next",
    "get_started": "Get Started",
    "activate_my_profile": "Yes! Activate my profile",
    "stay_setting": "No, stay {{setting}}",
    "complete_setup": "Complete Setup"
}
}
    