import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import { setupBrandNameProperties } from 'AppBranding';

import template from 'StudentNetwork/angularModule/views/onboarding_dialog_modal.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import studentNetworkOnboardingOne from 'images/student-network-onboarding-one.png';
import studentNetworkOnboardingTwo from 'images/student-network-onboarding-two.png';
import studentNetworkOnboardingThree from 'images/student-network-onboarding-three.png';
import studentNetworkOnboardingFour from 'images/student-network-onboarding-four.png';
import studentNetworkOnboardingFourNoConference from 'images/student-network-onboarding-four-no-conference.png';
import studentNetworkOnboardingOneNoEvents from 'images/student-network-onboarding-one-no-events.png';

import { StudentProfileFormConfigs } from 'FormConfigs';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingDialogModal', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                dismiss: '&',
            },

            link(scope) {
                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                const blurTarget = $('.world');
                const initialWekbitFilter = blurTarget.css('-webkit-filter');
                const initalFilter = blurTarget.css('filter');

                blurTarget.css('-webkit-filter', 'blur(3px)');
                blurTarget.css('filter', 'blur(3px)');

                scope.$on('$destroy', () => {
                    $('onboarding-dialog-modal').remove();

                    // remove/restore filter changes if possible
                    if (blurTarget) {
                        blurTarget.css('-webkit-filter', initialWekbitFilter || 'none');
                        blurTarget.css('filter', initalFilter || 'none');
                    }
                });

                const translationHelper = new TranslationHelper('student_network.onboarding_dialog_modal');

                //----------------------------
                // Dynamic Steps Config
                //----------------------------

                scope.form = {};
                scope.currentStep = 0;
                scope.steps = [
                    {
                        class: 'one',
                        title: translationHelper.get('step_one_title'),
                        message: translationHelper.get('step_one_message', { brandName: scope.brandNameShort }),
                        image: scope.currentUser.hasStudentNetworkEventsAccess
                            ? studentNetworkOnboardingOne
                            : studentNetworkOnboardingOneNoEvents,
                        mode: 'intro',
                    },
                    {
                        class: 'two',
                        title: translationHelper.get('step_two_title'),
                        message: translationHelper.get('step_two_message'),
                        image: studentNetworkOnboardingTwo,
                        mode: 'intro',
                    },
                    {
                        class: 'three',
                        title: translationHelper.get('step_three_title'),
                        message: translationHelper.get('step_three_message'),
                        image: studentNetworkOnboardingThree,
                        mode: 'intro',
                    },
                ];

                if (scope.currentUser.hasStudentNetworkEventsAccess) {
                    if (scope.currentUser.has_seen_student_network) {
                        // if they've gotten here and already seen the network modal, prune existing steps
                        scope.steps = [];
                    } else {
                        // otherwise, add the final, access-enabled step
                        scope.steps.push({
                            class: 'four',
                            title: translationHelper.get('step_four_title'),
                            message: translationHelper.get('step_four_message'),
                            image:
                                scope.currentUser.programType === 'emba'
                                    ? studentNetworkOnboardingFour
                                    : studentNetworkOnboardingFourNoConference,
                            mode: 'intro',
                        });

                        // if they have not been fully activated, prompt for that
                        if (scope.currentUser.pref_student_network_privacy !== 'full') {
                            scope.steps.push({ mode: 'activation' });
                        }
                    }

                    // account for any new or returning users that may need the prompt
                    addEmailPromptIfNeeded();
                }

                function addEmailPromptIfNeeded() {
                    if (scope.currentUser.missingRequiredStudentNetworkEmail) {
                        scope.steps.push({ mode: 'email' });
                    }
                }

                //----------------------------
                // Interaction
                //----------------------------

                scope.next = () => {
                    if (scope.currentStep === scope.steps.length - 1) {
                        scope.complete();
                    } else {
                        scope.currentStep += 1;
                    }
                };

                scope.activate = val => {
                    if (!scope.currentUser.ghostMode && val) {
                        scope.currentUser.career_profile.pref_student_network_privacy = 'full';
                        scope.currentUser.pref_student_network_privacy =
                            scope.currentUser.career_profile.pref_student_network_privacy;
                        addEmailPromptIfNeeded();
                    }
                    scope.shouldSave = true;
                    scope.next();
                };

                scope.updateEmail = () => {
                    scope.currentUser.career_profile.student_network_email = scope.form.student_network_email;
                    scope.currentUser.student_network_email = scope.currentUser.career_profile.student_network_email;
                    scope.shouldSave = true;
                    scope.next();
                };

                scope.complete = () => {
                    if (scope.shouldSave) {
                        scope.currentUser.career_profile.save();
                    }
                    scope.dismiss();
                };

                scope.networkSettingsPageNumber = () => {
                    const steps = StudentProfileFormConfigs;
                    const index = steps.findIndex(step => step.stepName === 'student_network_settings');
                    return index + 1;
                };

                //----------------------------
                // Dynamic Styles / Labels
                //----------------------------

                scope.getDotClass = index => (index <= scope.currentStep ? 'active' : 'inactive');
                scope.getButtonText = () =>
                    scope.currentStep === scope.steps.length - 1
                        ? translationHelper.get('get_started')
                        : translationHelper.get('next');
            },
        };
    },
]);
