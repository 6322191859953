import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_network_event_list_item.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import formattedDateForEvent from 'StudentNetwork/formattedDateForEvent';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkEventListItem', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const chinaRegionMode = $injector.get('ConfigFactory').getSync(true).chinaRegionMode();

        return {
            restrict: 'E',
            scope: {
                event: '<',
                eventsMapLayer: '<?',
            },
            templateUrl,

            link(scope, elem) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'wideLayout', {
                    get() {
                        return elem.hasClass('wide');
                    },
                });

                Object.defineProperty(scope, 'formattedDate', {
                    get() {
                        const timezone = chinaRegionMode ? 'Asia/Shanghai' : scope.currentUser?.timezone;
                        return timezone ? formattedDateForEvent(scope.event, timezone) : '';
                    },
                });

                //----------------------------
                // Image/placeholder handling
                //----------------------------

                scope.$watch('event.imageSrc', src => {
                    if (!src) {
                        scope.imageLoadError = true;
                        scope.eventListItemImageLoaded = true;
                    } else {
                        scope.imageLoadError = false;
                        scope.eventListItemImageLoaded = false;
                    }
                });

                // `imageLoadError` is set by the on-image-load directive, which is used
                // on the event image in the header
                scope.$watch('imageLoadError', () => {
                    if (scope.imageLoadError) {
                        scope.onEventListItemImageLoad();
                    }
                });

                scope.onEventListItemImageLoad = () => {
                    // The `eventListItemImageLoaded` flag is used to control the visibility of the list item's details
                    // and footer sections. When the flag gets flipped to true, we render the event those sections.
                    scope.eventListItemImageLoaded = true;
                    safeApply(scope);
                };

                //----------------------------
                // List item interactions
                //----------------------------

                scope.onViewEvent = () => {
                    // We don't pass in an eventsMapLayer
                    // in the admin.
                    if (scope.eventsMapLayer) {
                        scope.eventsMapLayer.onViewEvent(scope.event);
                    }
                };
            },
        };
    },
]);
