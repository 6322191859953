import { useContext, useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { isEqual } from 'lodash/fp';
import AMapContext from './AMapContext';
import StudentCluster from './StudentCluster';
import useLoadStudentMarkers from './useLoadStudentMarkers';

export default function StudentMapGroup(props) {
    const { map, mapInfo, filters, advancedSearchModel, setAdvancedSearchModel, studentsMapLayer, isMobile } = props;

    const [group, setGroup] = useState();
    const [children, setChildren] = useState();

    const prevState = useRef(null);

    const AMap = useContext(AMapContext);

    const features = useLoadStudentMarkers(group, filters, mapInfo, studentsMapLayer);

    useEffect(() => {
        if (group) {
            return;
        }
        const instance = new AMap.OverlayGroup();
        instance.setMap(map);
        setGroup(instance);
    }, [AMap, map, group]);

    useEffect(() => {
        if (!mapInfo || !filters) {
            return;
        }

        if (!prevState.current) {
            prevState.current = { filters, zoom: mapInfo.zoom };
        }

        // If just move the map(zoom & filters are not changed), do not reset focus.
        if (!(isEqual(filters)(prevState.current.filters) && mapInfo.zoom === prevState.current.zoom)) {
            setAdvancedSearchModel(prev => {
                const state = { focusedFeature: null, inAdvancedSearchMode: prev.inAdvancedSearchMode };
                return isEqual(prev)(state) ? prev : state;
            });
            prevState.current = { filters, zoom: mapInfo.zoom };
        }
    }, [filters, mapInfo, setAdvancedSearchModel]);

    useEffect(() => {
        if (!features) {
            return;
        }

        const clusters = features.map(feature => (
            <StudentCluster
                key={[feature.properties.cluster_hull, feature.properties.count].join(' | ')}
                map={map}
                group={group}
                feature={feature}
                setAdvancedSearchModel={setAdvancedSearchModel}
            />
        ));

        setChildren(clusters);
    }, [map, group, features, setAdvancedSearchModel]);

    useEffect(() => {
        function panToCluster(cluster) {
            const [lng, lat] = cluster.geometry.coordinates;
            const { x, y } = map.lngLatToContainer(new AMap.LngLat(lng, lat));
            const centerPixel = new AMap.Pixel(x - 450, y);
            const center = map.containerToLngLat(centerPixel);
            map.panTo(center, 100);
        }

        const { focusedFeature, inAdvancedSearchMode } = advancedSearchModel;

        $('network-amap .amap-markers').toggleClass('hasfocus', !!focusedFeature);
        $('.student-cluster').removeClass('focused');

        if (focusedFeature) {
            $(`.student-cluster[data-id=${focusedFeature.id}]`).addClass('focused');

            if (!inAdvancedSearchMode && !isMobile) {
                panToCluster(focusedFeature);
            }
        }
    }, [AMap, map, advancedSearchModel, isMobile]);

    return <>{group && features && children}</>;
}
