
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list_item = window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list_item || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list_item = {...window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_list_item, ...{
    "view_event": "View",
    "view_event_tooltip_details_unavailable": "Event details are only available to MBA and Executive MBA students and alumni."
}
}
    