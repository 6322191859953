import 'angular-animate';
import 'angular-bind-html-compile';
import 'ngmap';

import 'AddToCalendarWidget/angularModule';
import 'DialogModal/angularModule';
import 'EmailInput/angularModule';
import 'SafeApply/angularModule';
import 'Translation/angularModule';
import 'EventLogger/angularModule';
import 'FrontRoyalApiErrorHandler/angularModule';
import 'FrontRoyalForm/angularModule';
import 'ImageFadeInOnLoad/angularModule';
import 'IsMobile/angularModule';
import 'Navigation/angularModule';
import 'OnImageLoad/angularModule';
import 'ScrollHelper/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'AMap/angularModule';

export default angular.module('FrontRoyal.StudentNetwork', [
    'angular-bind-html-compile',
    'ngAnimate',
    'onImageLoad',
    'Translation',
    'DialogModal',
    'SiteMetadata',
    'EventLogger',
    'safeApply',
    'isMobile',
    'imageFadeInOnLoad',
    'scrollHelper',
    'FrontRoyal.ApiErrorHandler',
    'FrontRoyal.EmailInput',
    'FrontRoyal.Form',
    'FrontRoyal.Navigation',
    'FrontRoyal.AddToCalendarWidget',
    'ngMap',
    'FrontRoyal.AMap',
]);
