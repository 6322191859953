import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_network_return_to_map_button.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkReturnToMapButton', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            scope: {
                returnToMapCallback: '&',
            },
            templateUrl,

            link() {},
        };
    },
]);
