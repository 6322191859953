
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_recommended_event_list = window.Smartly.locales.modules.en.back_royal.student_network.student_network_recommended_event_list || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_recommended_event_list = {...window.Smartly.locales.modules.en.back_royal.student_network.student_network_recommended_event_list, ...{
    "header_when_filters_closed": "Recommended Events",
    "header_when_filters_open": "Search All Events",
    "upcoming": "Upcoming",
    "past": "Past Events",
    "search_all": "Search all events...",
    "search_short": "Search...",
    "recommended_tooltip": "Events that are relevant to your location or cohort",
    "cancel": "Cancel",
    "keyword_search_filter_placeholder": "Search event titles, descriptions, etc...",
    "location_filter_placeholder": "Search by event location...",
    "event_type_filter_placeholder": "Search by event type...",
    "all_event_types": "All Event Types",
    "featured": "Featured"
}
}
    