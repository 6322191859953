import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import 'FrontRoyalUiBootstrap/popover';
import getDistanceBetweenElementCenters from 'getDistanceBetweenElementCenters';

/*
    This class is responsible for loading and setting up the map data layer representing student events.
*/
angularModule.factory('EventsMapLayer', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const TranslationHelper = $injector.get('TranslationHelper');
        const StudentNetworkEvent = $injector.get('StudentNetworkEvent');
        const $timeout = $injector.get('$timeout');
        const $window = $injector.get('$window');
        const $document = $injector.get('$document');
        const isMobile = $injector.get('isMobile');
        const $q = $injector.get('$q');

        /* eslint-disable-next-line func-names */
        return SuperModel.subclass(function () {
            Object.defineProperty(this.prototype, 'eventTypesAvailableForMapFilters', {
                get() {
                    if (!_.some(this.$$eventTypesAvailableForMapFilters)) {
                        this._setEventTypesAvailableForMapFilters();
                    }
                    return this.$$eventTypesAvailableForMapFilters;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'showRecommendedEventsList', {
                get() {
                    return (
                        this.activated && ($window.innerWidth >= 992 || this.mobileState.showingRecommendedEventsList)
                    );
                },
            });

            // The StudentNetworkMapViewModel triggers a refresh of the active map layer when the
            // user has stopped interacting with the map for a certain amount of time i.e. when the
            // map has become "idle". This is useful in the Students & Alumni tab because the data
            // in the map is dependent on map zoom level and the sidebar depends on this data as
            // well. However, for the Events tab, the map’s zoom level doesn’t mean that we have to
            // trigger a refresh because the data on the map is loaded up all at once when the events
            // map layer is first activated. This flag disables this behavior so that we can be more
            // performant and avoid unnecessary API calls here for the events map layer.
            Object.defineProperty(this.prototype, 'supportsRefreshOnIdleMapEvent', {
                value: false,
            });

            Object.defineProperty(this.prototype, 'currentRecommendedEventsListTab', {
                get() {
                    if (!this.$$currentRecommendedEventsListTab) {
                        this.$$currentRecommendedEventsListTab = 'upcoming';
                    }
                    return this.$$currentRecommendedEventsListTab;
                },
                set(val) {
                    this.$$currentRecommendedEventsListTab = val;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isAMap', {
                get() {
                    // There is no obvious attribute of Google Map to distinguish who is it, so use the AMap's CLASS_NAME.
                    return this.map?.CLASS_NAME === 'AMap.Map';
                },
            });

            return {
                // When using the network-map directive (outside of China, using google maps), a studentNetworkMapViewModel will be passed in.
                // When using the network-amap directive (within china, not using gaode maps), handlers and AMap will be passed in and studentNetworkMapViewModel will not be
                // Only the AMap will give the 'handlers' & 'AMap' parameters.
                initialize({ map, userCohortIds, studentNetworkMapViewModel, handlers, AMap }) {
                    if (!studentNetworkMapViewModel) {
                        if (!handlers || !AMap) {
                            throw new Error(
                                'handlers and AMap are required when there is no studentNetworkMapViewModel',
                            );
                        }
                    }

                    if (!userCohortIds) throw new Error('userCohortIds is required');
                    if (!map) throw new Error('map is required');
                    this.map = map;
                    this.studentNetworkMapViewModel = studentNetworkMapViewModel;
                    this.name = 'events';
                    this.handlers = handlers;
                    this.AMap = AMap;
                    this.userCohortIds = userCohortIds;

                    this._clearEventsForRecommendedEventsList();

                    // There are two separate sets of filters that the EventsMapLayer needs to keep track of.
                    // The `serverFilters` object is intended for server-side filtering via the recommended
                    // events list, while the `mapFilters` object is intended for client-side filtering of
                    // the events displayed on the map.
                    this.resetServerFilters();
                    this.mapFilters = {};
                    this.refreshFilters = {
                        include_tbd: true,
                        cohort_ids: this.userCohortIds,
                    };

                    this.loading = 0;

                    this.advancedSearchOpen = false;

                    // state tracking for the mobile view
                    this.mobileState = {
                        // On desktop, the list is always active (there is no
                        // way to show/hide it). However, on mobile, the list
                        // is manually toggled on by clicking  'Recommended Events' in the filter box
                        // or toggled off by clicking on 'Return to Map'.
                        showingRecommendedEventsList: false,
                    };

                    // Note: unlike the students map layer, the events shown on the map are always independent of the events shown on the right-hand
                    // recommended events sidebar. Need to keep this in mind when manage data loading in this model. For example, we might do something
                    // like initially load all of the events the user can see, then split it into those that are viewable on the map (eventFeatures) and
                    // those that are viewable in the recommended events sidebar (everything, including online events). From there on out, any changes to
                    // recommended event filters or map filters would independently refresh their respective lists of events. Or, maybe we never refresh the
                    // map's list of events, instead doing client-side filtering by event type, and only refresh the sidebar event list from there on out?

                    this.translationHelper = new TranslationHelper('student_network.network_map_filter_box');
                    this._updateSelectedMapFiltersSummary();
                },

                deactivate() {
                    this.activated = false;
                    this._clearEventFeatures();
                    this._clearEventsForRecommendedEventsList();
                    this._clearEventTypesAvailableForMapFilters();
                    this.loading = 0;

                    this.mobileState.showingRecommendedEventsList = false;

                    this.closeAllAmbiguousEventMarkerPopovers();
                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    if (this.isAMap) {
                        this.handlers.setShowRecommendedEventsList(this.showRecommendedEventsList);
                    } else {
                        this._enableMapGestureHandling();
                    }

                    this.currentRecommendedEventsListTab = null;
                },

                activate() {
                    // this is where we'd set up listeners, if necessary
                    this.activated = true;
                    this.mobileState.showingRecommendedEventsList = true;
                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    this.handlers?.setShowRecommendedEventsList(this.showRecommendedEventsList);
                },

                toggleRecommendedEventsList() {
                    this.mobileState.showingRecommendedEventsList = !this.mobileState.showingRecommendedEventsList;
                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    this.handlers?.setShowRecommendedEventsList(this.showRecommendedEventsList);
                },

                refresh() {
                    if (this.loading || !this.activated) {
                        return {};
                    }

                    return this._loadEvents()
                        .catch(err => {
                            if (err) {
                                this.loading -= 1;
                            }
                            return null;
                        })
                        .then(response => {
                            if (response && this.activated) {
                                this.events = response.result;

                                this.resetMapFilters();
                                this._updateSelectedMapFiltersSummary();

                                // Compute the `eventTypesAvailableForMapFilters` ahead of time so that
                                // they're ready for use if/when the `showEventTypeFilters` method is called.
                                this._setEventTypesAvailableForMapFilters();

                                this.resetServerFilters();
                                this._updateEventsForRecommendedEventsList(this.events);

                                this.loading -= 1;
                            }
                        });
                },

                resetServerFilters() {
                    this.serverFilters = {
                        include_tbd: true,
                        end_time: undefined,
                        keyword_search: undefined,
                        featured: undefined,
                        places: undefined, // the server expects this filter to be plural, but the UI only supports filtering by a single place
                        event_type: [], // the server expects this filter to be singular, but the UI supports filtering by multiple event types
                        cohort_ids: this.userCohortIds,
                    };
                },

                resetMapFilters() {
                    this._updateAndApplyMapFilters({
                        eventTypes: [],
                    });
                },

                //--------------------
                // Event marker interactions
                //--------------------

                // When an event-marker is clicked, one of two things happens.
                // 1. A modal popup with event details is shown, or
                // 2. A popover with any visually ambiguous event features is shown
                onEventMarkerClick(eventFeature) {
                    const event = this._getEventForEventId(eventFeature.uid);
                    const visuallyAmbiguousEvents = this._getVisuallyAmbiguousEvents(eventFeature.uid);

                    if (_.some(visuallyAmbiguousEvents)) {
                        // If we found any ambiguous events, we also want to be sure to include the event
                        // the user actually clicked on (which won't be in visuallyAmbiguousEventIds).
                        visuallyAmbiguousEvents.unshift(event);
                        eventFeature.ambiguousEvents = visuallyAmbiguousEvents;
                        const showPopover = () => {
                            // The 'moveend' event needs to add/remove only when the current map is AMap.
                            if (this.isAMap) {
                                this.map.off('moveend', showPopover);
                            }
                            this.openAmbiguousEventMarkerPopover(eventFeature);
                        };

                        if (isMobile()) {
                            showPopover();
                        } else {
                            // There's a chance the event-marker isn't in an optimal location. Just in case,
                            // we want to move the map such that the ambiguous popover isn't cut-off

                            // Users who have limited access to student network events see anonymized data. The server ensures
                            // that the appropriate data is included in the JSON that's returned to the client based on the user's
                            // access level, so in this directive, if there are anonymized place details present, the user has
                            // restricted access; however, if the anonymized details are undefined, the user has full access.
                            const placeDetails = event.place_details_anonymized || event.place_details;
                            // The AMap API is different with the Google Map API, it needs to be judged before using.
                            const latlng = this.isAMap
                                ? new this.AMap.LngLat(placeDetails.lng, placeDetails.lat)
                                : new $window.google.maps.LatLng(placeDetails.lat, placeDetails.lng);
                            // Get the pixel-based point so we can construct a bounds in screen-space
                            const scale = 2 ** this.map.getZoom();
                            const worldCoordinateEventMarker = this.isAMap
                                ? this.map.lnglatToPixel(latlng)
                                : this.map.getProjection().fromLatLngToPoint(latlng);
                            // Construct a bounds object based on the SW and NE corner of the popover
                            // See student_network_event_ambiguous_popover.scss for these dimensions
                            const popoverWidth = 317;
                            const popoverHeight = 479;
                            const popoverArrowWidth = 20;
                            const Point = this.isAMap ? this.AMap.Pixel : $window.google.maps.Point;
                            const worldCoordinateSW = new Point(
                                worldCoordinateEventMarker.x,
                                worldCoordinateEventMarker.y - (popoverHeight * 0.5) / scale,
                            );
                            const worldCoordinateNE = new Point(
                                worldCoordinateEventMarker.x + (popoverWidth + popoverArrowWidth) / scale,
                                worldCoordinateEventMarker.y + (popoverHeight * 0.5) / scale,
                            );
                            const latLngBounds = this.isAMap
                                ? new this.AMap.Bounds(
                                      this.map.pixelToLngLat(worldCoordinateSW),
                                      this.map.pixelToLngLat(worldCoordinateNE),
                                  )
                                : new $window.google.maps.LatLngBounds(
                                      this.map.getProjection().fromPointToLatLng(worldCoordinateSW),
                                      this.map.getProjection().fromPointToLatLng(worldCoordinateNE),
                                  );
                            const padding = {
                                bottom: 10,
                                left: 10,
                                right: 10,
                                top: 10,
                            };

                            // Strategy: listen for the idle event from the map to know when the panToBounds is done.
                            // However, if panToBounds doesn't move the map, there will be no idle event dispatched.
                            // So, also measure the center now, and check it again soon after. If it hasn't changed,
                            // we can know with high certainty it's not going to. In that case, cancel the idle listener
                            // and just invoke showPopover.
                            let idleListener = null;
                            // The 'moveend' event needs to add/remove only when the current map is AMap.
                            if (this.isAMap) {
                                this.map.on('moveend', showPopover);
                            } else {
                                idleListener = $window.google.maps.event.addListenerOnce(this.map, 'idle', showPopover);
                            }
                            const oldCenter = this.map.getCenter();

                            // finally: animate the map to make the popover have enough space
                            if (this.isAMap) {
                                this.map.setBounds(latLngBounds);
                            } else {
                                this.map.panToBounds(latLngBounds, padding);
                            }

                            $timeout(() => {
                                const newCenter = this.map.getCenter();
                                if (newCenter.equals(oldCenter)) {
                                    if (!this.isAMap) {
                                        $window.google.maps.event.removeListener(idleListener);
                                    }
                                    showPopover();
                                }
                            }, 100);
                        }
                    } else {
                        this.closeAmbiguousEventMarkerPopover(eventFeature);
                        this.showEventDetails(event);
                    }
                },

                //--------------------
                // Event details
                //--------------------

                onViewEvent(event) {
                    // If we're viewing a mappable event, there's a chance we clicked
                    // "view" from inside a popover. The popover does not necessarily belong
                    // to the corresponding event-marker for the event we want to view so,
                    // to be safe, we just close any open popovers.
                    if (event.mappable) {
                        this.closeAllAmbiguousEventMarkerPopovers();
                    }
                    this.showEventDetails(event);
                },

                showEventDetails(event) {
                    if (!event || !_.some(this.events)) {
                        return;
                    }

                    this.showingEventTypeFiltersModal = false;

                    this.studentNetworkMapViewModel.showEventDetailsModal(event);
                },

                showEventTypeFilters() {
                    this.showingEventTypeFiltersModal = true;
                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    this.handlers?.setShowingEventTypeFiltersModal(true);
                },

                hideEventTypeFilters() {
                    this.showingEventTypeFiltersModal = false;
                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    this.handlers?.setShowingEventTypeFiltersModal(false);
                },

                applyEventTypeFilters(newEventTypes) {
                    this.showingEventTypeFiltersModal = false;
                    this._updateAndApplyMapFilters({
                        eventTypes: newEventTypes,
                    });
                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    this.handlers?.setShowingEventTypeFiltersModal(false);
                },

                //--------------------
                // Popover handling
                //--------------------

                openAmbiguousEventMarkerPopover(eventFeature) {
                    $document.one(
                        'mousedown.events.disambiguation.popover',
                        this.handleClickOnDocumentWhilePopoverVisible.bind(this),
                    );
                    // The 'mousedown' event needs to add/remove only when the current map is AMap.
                    if (this.isAMap) {
                        this.map.on('mousedown', () => {
                            $document.trigger('mousedown.events.disambiguation.popover');
                        });
                    }
                    $timeout(() => {
                        eventFeature.popoverIsOpen = true;
                        this.handleGestureControlsOnPopoverStateChange();
                    });
                },

                closeAmbiguousEventMarkerPopover(eventFeature) {
                    $document.off('mousedown.events.disambiguation.popover');
                    if (this.isAMap) {
                        this.map.off('mousedown');
                    }
                    $timeout(() => {
                        eventFeature.popoverIsOpen = false;
                        this.handleGestureControlsOnPopoverStateChange();
                    });
                },

                closeAllAmbiguousEventMarkerPopovers() {
                    _.forEach(this.eventFeatures, eventFeature => {
                        if (eventFeature.popoverIsOpen) {
                            this.closeAmbiguousEventMarkerPopover(eventFeature);
                        }
                    });
                },

                handleClickOnDocumentWhilePopoverVisible(event) {
                    const popover = $('.student-network-event-ambiguous-popover.popover');

                    // make sure the tooltip/popover link or tool tooltip/popover itself were not clicked
                    if (popover && popover.length && !popover[0].contains(event.target)) {
                        this.closeAllAmbiguousEventMarkerPopovers();
                    }
                },

                handleGestureControlsOnPopoverStateChange() {
                    // The logic only for Google Map.
                    if (!this.isAMap) {
                        // If any popovers are showing, we should disable gesture controls.
                        // If no popovers are showing, gesture controls should be enabled.
                        if (
                            !_.find(this.eventFeatures, {
                                popoverIsOpen: true,
                            })
                        ) {
                            this._enableMapGestureHandling();
                        } else {
                            this._disableMapGestureHandling();
                        }
                    }
                },

                //---------------------
                // Data Loading and Map Features
                //---------------------

                onSearchEventsClick() {
                    this.advancedSearchOpen = true;

                    // We've already loaded up all of the events that would be shown in the 'upcoming' tab
                    // because those are the events that are loaded by the events map layer by default,
                    // so we don't need to bother fetching any events from the server, but for the 'past_events'
                    // tab, we may not have any of the events that would be shown in the events list, so we
                    // need to fetch the events from the server.
                    if (this.currentRecommendedEventsListTab === 'past_events') {
                        this.fetchPastEvents();
                    } else {
                        this._updateEventsForRecommendedEventsList(this.events);
                    }
                },

                fetchCurrentAndUpcomingEvents() {
                    this.serverFilters.end_time = undefined;
                    this.serverFilters.include_tbd = true;
                    this.serverFilters.featured = undefined;
                    this.applyServerFilters();
                },

                fetchPastEvents() {
                    this.serverFilters.end_time = Math.floor(Date.now() / 1000);
                    this.serverFilters.include_tbd = false;
                    this.serverFilters.featured = undefined;
                    this.applyServerFilters();
                },

                fetchFeaturedEvents() {
                    this.serverFilters.featured = true;
                    this.serverFilters.end_time = undefined;
                    this.serverFilters.include_tbd = true;
                    this.applyServerFilters();
                },

                applyServerFilters() {
                    return this._loadEvents(this.serverFilters)
                        .catch(err => {
                            if (err) {
                                this.loading -= 1;
                            }
                            return null;
                        })
                        .then(response => {
                            if (response && this.activated) {
                                const fetchedEvents = response.result;
                                this._mergeEventsIntoDataStore(fetchedEvents);
                                this._updateEventsForRecommendedEventsList(fetchedEvents);

                                // Some new events may be been returned from the server that have an event_type
                                // that may not have been included in the `eventTypesAvailableForMapFilters` cache,
                                // so we reset this value to make sure that any new event types are available.
                                this._setEventTypesAvailableForMapFilters();

                                this.loading -= 1;
                            }
                        });
                },

                _loadEvents(filters = this.refreshFilters) {
                    if (!this.map) {
                        // Return false wrapped in a promise to indicate to the caller that we didn't
                        // trigger an API call and therefore didn't increment the loading flag. Therefore,
                        // callers of this method should catch the rejected _loadEvents call and appropriately
                        // decrement the loading flag depending on the rejected value.
                        return $q.reject(false);
                    }

                    this.loading += 1;

                    return StudentNetworkEvent.index({
                        filters,
                    });
                },

                _mergeEventsIntoDataStore(events) {
                    if (!this.events) {
                        this.events = events;
                    } else {
                        this.events = _.uniqBy(this.events.concat(events), event => event.id);
                    }
                },

                _addEventMarkersToMap(events = []) {
                    if (!this.map) {
                        return;
                    }

                    // trigger the view refresh
                    // NOTE: Not all events can be placed on the map, so we need to make sure
                    // to only get the mappable events and the current and upcoming events.
                    const mappableEvents = this._filterForMappableEvents(events);
                    const eventsForEventFeatures = this._filterForCurrentAndUpcomingEvents(mappableEvents);
                    this.eventFeatures = eventsForEventFeatures.map(event => {
                        // We add the uid so that when we refresh data, we don't replace
                        // existing features with identical replacements.  See also the track-by in network_map.html
                        const feature = _.clone(event);

                        // we're not clustering, so in theory the event ID should be good enough here?
                        // The only edge case this wouldn't cover is an event that changes location between refreshes; the dot wouldn't move
                        feature.uid = event.id;

                        return feature;
                    });
                    // If current map is AMap, it needs to call the callback function which comes with the handlers parameter.
                    this.handlers?.setEventFeatures(this.eventFeatures);
                },

                //-------------------------------
                // Map Filter Helper Methods
                //-------------------------------

                _updateAndApplyMapFilters(filters) {
                    this._updateMapFilters(filters);
                    this._updateSelectedMapFiltersSummary();
                    this._applyMapFilters();
                },

                _updateMapFilters(newFilters) {
                    _.extend(this.mapFilters, newFilters);
                },

                _updateSelectedMapFiltersSummary() {
                    const selectedFiltersSummaryParts = [];
                    const studentNetworkEventTranslationHelper = new TranslationHelper(
                        'student_network.student_network_event',
                    );

                    _.forEach(this.mapFilters, (value, key) => {
                        if (_.some(value)) {
                            if (key === 'eventTypes') {
                                _.forEach(value, val =>
                                    selectedFiltersSummaryParts.push(
                                        `<span>${studentNetworkEventTranslationHelper.get(val)}</span>`,
                                    ),
                                );
                            }
                        }
                    });

                    this.selectedMapFiltersSummary = _.some(selectedFiltersSummaryParts)
                        ? selectedFiltersSummaryParts.join(' ')
                        : `<span>${this.translationHelper.get('showing_all_events')}</span>`;
                },

                _applyMapFilters() {
                    const events = this._filterEventsByEventType(this.events, this.mapFilters);
                    this._addEventMarkersToMap(events);
                },

                // Filters the passed in `events` for all of the events with an `event_type`
                // contained in the `eventTypes` array property on the passed in `filters`.
                _filterEventsByEventType(events, filters) {
                    if (!filters || !_.some(filters.eventTypes)) {
                        return events;
                    }

                    // create a map of the event types to filter by for better performance
                    const eventTypesMap = _.zipObject(
                        filters.eventTypes,
                        filters.eventTypes.map(() => true),
                    );
                    return _.filter(events, event => eventTypesMap[event.event_type]);
                },

                _filterForMappableEvents(events) {
                    return _.filter(events, event => event.mappable);
                },

                _filterForCurrentAndUpcomingEvents(events) {
                    const now = Date.now();
                    return _.filter(events, event => event.date_tbd || event.end_time * 1000 > now);
                },

                _filterForPastEvents(events) {
                    const now = Date.now();
                    return _.filter(events, event => event.end_time * 1000 <= now);
                },

                _filterForRecommendedEvents(events) {
                    return _.filter(events, event => event.recommended);
                },

                _setEventTypesAvailableForMapFilters() {
                    // Get the event types that should be shown in the event type filters box UI.
                    const eventTypesFromEvents = _.chain(this.events || [])
                        .map('event_type')
                        .uniq()
                        .value();
                    const eventTypesFromEventsMap = _.zipObject(
                        eventTypesFromEvents,
                        eventTypesFromEvents.map(() => true),
                    );
                    this.$$eventTypesAvailableForMapFilters = _.chain(
                        Object.keys(StudentNetworkEvent.EVENT_TYPE_CONFIGS_MAP),
                    )
                        .filter(eventType => {
                            const eventTypeConfig = StudentNetworkEvent.EVENT_TYPE_CONFIGS_MAP[eventType];
                            if (!eventTypeConfig.mappable) {
                                return false;
                            }

                            // In this context, certain event types are always shown in the filter box UI,
                            // while others are only made visible if there are any events available with that `event_type`.
                            return eventTypeConfig.visibleInEventTypeFiltersOnlyWhenAny
                                ? eventTypesFromEventsMap[eventType]
                                : true;
                        })
                        .sortBy(
                            eventType => StudentNetworkEvent.EVENT_TYPE_CONFIGS_MAP[eventType].eventTypeFiltersOrder,
                        )
                        .value();
                },

                //------------------------------
                // Additional Helper Methods
                //------------------------------

                // remove old features
                _clearEventFeatures() {
                    this.eventFeatures = undefined;
                },

                _clearEventsForRecommendedEventsList() {
                    this.eventsForRecommendedEventsList = [];
                },

                _clearEventTypesAvailableForMapFilters() {
                    this.$$eventTypesAvailableForMapFilters = undefined;
                },

                _getEventsForEventIds(eventIds) {
                    return _.map(eventIds, eventId => this._getEventForEventId(eventId));
                },

                _getEventForEventId(eventId) {
                    return _.find(this.events, {
                        id: eventId,
                    });
                },

                _getEventFeatureForEventId(eventId) {
                    return _.find(this.eventFeatures, feature => feature.uid === eventId);
                },

                _getVisuallyAmbiguousEvents(eventId) {
                    // This threshold is deliberate. Currently, the icons for event-markers
                    // have dimensions of 20px X 20px. Each icon therefore has a radius of
                    // 10px. If the computed distance between the markers is less than 20,
                    // the icons are definitely overlapping.
                    const distanceThreshold = 20;
                    // AMap using event_marker_amap to show the events, but Google Map using event_marker.
                    const thisMarker = this.isAMap
                        ? $(`.event-marker[data-id="${eventId}"]`).get(0)
                        : $(`event-marker[data-id="${eventId}"]`).get(0);
                    const visuallyAmbiguousEventMarkers = _.reject(
                        this.isAMap ? $('.event-marker').get() : $('event-marker').get(),
                        marker => $(marker).attr('data-id') === eventId,
                    ) // Don't include the one that was clicked
                        .filter(marker => getDistanceBetweenElementCenters(thisMarker, marker) <= distanceThreshold); // Do include any other markers within the threshold
                    const visuallyAmbiguousEventIds = _.map(visuallyAmbiguousEventMarkers, marker =>
                        $(marker).attr('data-id'),
                    );
                    return this._getEventsForEventIds(visuallyAmbiguousEventIds);
                },

                // This method determines what events are shown in the main event list in the student network
                // based on the map layer's current state. NOTE: Even though the recommended events list may
                // sound like it's only responsibility is to show the events to the user that are "recommended",
                // it's also responsible allowing the user to filter through ALL current and past events regardless
                // of whether or not they're "recommended" for the user.
                _updateEventsForRecommendedEventsList(events) {
                    const filterMethod =
                        this.currentRecommendedEventsListTab === 'upcoming' ||
                        this.currentRecommendedEventsListTab === 'featured'
                            ? '_filterForCurrentAndUpcomingEvents'
                            : '_filterForPastEvents';
                    const eventsForCurrentRecommendedEventsListTab = this[filterMethod](events);
                    this.eventsForRecommendedEventsList = this.advancedSearchOpen
                        ? eventsForCurrentRecommendedEventsListTab
                        : this._filterForRecommendedEvents(eventsForCurrentRecommendedEventsListTab);
                },

                //--------------------
                // Ngmap Gesture controls
                //--------------------

                _disableMapGestureHandling() {
                    // Whenever we have a popover open, we disable gesture handling on the map
                    // because the map will usurp any scroll events. It also doesn't make sense
                    // to allow gestures inside of Cordova when a popover is open, because the
                    // popover occupies the entire screen.
                    this.map.setOptions({
                        gestureHandling: 'none',
                    });
                },

                _enableMapGestureHandling() {
                    // Whenever a popover's scope is destroyed, or we deactivate this map layer,
                    // we want to be sure to re-enable gesture handling.
                    this.map.setOptions({
                        gestureHandling: 'auto',
                    });
                },
            };
        });
    },
]);
