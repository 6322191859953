
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.network_map_filter_box = window.Smartly.locales.modules.en.back_royal.student_network.network_map_filter_box || {};
window.Smartly.locales.modules.en.back_royal.student_network.network_map_filter_box = {...window.Smartly.locales.modules.en.back_royal.student_network.network_map_filter_box, ...{
           
    "students_and_alumni": "Students & Alumni",
    "events": "Events",

                     
    "no_preferences_selected": "No Filters Selected",
    "editing_preferences": "Advanced Search Filters…",
    "plus_n_industries": " (+{count} {count, plural, =1{industry} other{industries}})",
    "plus_n_student_network_looking_for": " (+{count} {count, plural, =1{option} other{options}})",
    "plus_n_student_network_interests": " (+{count} {count, plural, =1{interest} other{interests}})",
    "plus_n_places": " (+{count} {count, plural, =1{location} other{locations}})",
    "plus_n_class": " (+{count} {count, plural, =1{program} other{programs}})",

              
    "network_filter": "Network Filter",
    "keyword_search": "Keyword Search…",
    "all": "All",
    "all_classes": "All Classes",
    "all_mba": "All MBA Classes",
    "all_emba": "All EMBA Classes",
    "mine": "My Class",
    "graduation_status": "Students & Alumni",
    "not_graduated": "Current Students",
    "graduated": "Alumni",
    "apply_filters": "Apply Filters",
    "reset_filters": "Reset Filters",
    "advanced_search_active": "Advanced Search Active",
    "edit_search": "Edit Search",
    "reset": "Reset",
    "mba": "MBA",
    "emba": "EMBA",
    "executive_education": "Executive Education",
    "msse": "MSSE",
    "msba": "MSBA",

                       
    "filter_map": "Filter Map...",
    "showing_all_events": "Showing all events",
    "recommended_events": "Recommended Events…",

           
    "advanced_search": "Advanced Search…",
    "return_to_map": "Return to Map",

    "quanticDegreePrograms": "Quantic Degree Programs",
    "valarDegreePrograms": "Valar Degree Programs",
    "execEdPrograms": "Executive Education Programs"
}
}
    