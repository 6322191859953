
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_profile_list = window.Smartly.locales.modules.en.back_royal.student_network.student_profile_list || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_profile_list = {...window.Smartly.locales.modules.en.back_royal.student_network.student_profile_list, ...{
    "contact": "Contact",
    "interests": "Interests",
    "looking_for": "Looking For",
    "linkedin": "LinkedIn",
    "github": "Github",
    "students_near": "Students Near",
    "remove_filter": "Remove Filter",
    "no_students_found": "No Students Found",
    "try_adjusting_your_filters": "Try adjusting your filters"
}
}
    