import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'StudentNetwork/angularModule/views/partial_access_modal.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('partialAccessModal', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const dateHelper = $injector.get('dateHelper');
        const Cohort = $injector.get('Cohort');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                dismiss: '&',
                dismissPartialAccessModal: '<?',
            },
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);

                scope.showApply =
                    (!scope.currentUser.hasEverApplied || scope.currentUser.isNotJoiningProgramOrHasBeenExpelled) &&
                    Cohort.isDegreeProgram(scope.currentUser.programType);

                if (scope.showApply) {
                    const applicableAdmissionRound = scope.currentUser.relevantCohort.getApplicableAdmissionRound();
                    scope.admissionDeadline =
                        applicableAdmissionRound &&
                        dateHelper.formattedUserFacingMonthDayLong(applicableAdmissionRound.applicationDeadline);
                }

                scope.dismissModal = () => {
                    if (scope.dismissPartialAccessModal) {
                        scope.dismissPartialAccessModal.setShowPartialAccessModal(false);
                    } else {
                        scope.dismiss();
                    }
                };
            },
        };
    },
]);
