import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import 'FrontRoyalUiBootstrap/popover';
import template from 'StudentNetwork/angularModule/views/event_marker_amap.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('eventMarkerAmap', [
    '$injector',

    function factory($injector) {
        const StudentNetworkEvent = $injector.get('StudentNetworkEvent');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                amap: '<',
                group: '<',
                feature: '<',
                eventsMapLayer: '<',
            },
            link(scope, elem) {
                scope.icon = StudentNetworkEvent.EVENT_TYPE_CONFIGS_MAP[scope.feature.event_type].icon;

                // Users who have limited access to student network events see anonymized data. The server ensures
                // that the appropriate data is included in the JSON that's returned to the client based on the user's
                // access level, so in this directive, if there are anonymized place details present, the user has
                // restricted access; however, if the anonymized details are undefined, the user has full access.
                const placeDetails = scope.feature.place_details_anonymized || scope.feature.place_details;
                const markerOpt = {
                    position: [placeDetails.lng, placeDetails.lat],
                    content: elem[0],
                    anchor: 'center',
                    offset: new scope.amap.Pixel(0, 0),
                };

                const marker = new scope.amap.Marker(markerOpt);
                scope.group.addOverlay(marker);
            },
        };
    },
]);
