import { useEffect, useState } from 'react';
import { aMapLoaderWrapper, logAMapLoadError } from 'AMap';

export default function useAMap(props) {
    const { $injector } = props;
    const [AMap, setAMap] = useState();
    const ConfigFactory = $injector.get('ConfigFactory');
    const ErrorLogService = $injector.get('ErrorLogService');
    const amapWebKey = ConfigFactory.getSync(true).amap_js_api_key;
    const amapSecurityJsCode = ConfigFactory.getSync(true).amap_js_api_code;
    const mapConfig = $injector.get('AMAP_CONFIG');

    useEffect(() => {
        const loadAMap = async () => {
            try {
                const map = await aMapLoaderWrapper(
                    {
                        key: amapWebKey,
                        version: mapConfig.version,
                        plugins: mapConfig.toolbar.plugins,
                    },
                    amapSecurityJsCode,
                );
                setAMap(map);
            } catch (e) {
                logAMapLoadError(ErrorLogService, e);
            }
        };
        loadAMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amapWebKey, amapSecurityJsCode, mapConfig]);

    return AMap;
}
