import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import * as userAgentHelper from 'userAgentHelper';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'StudentNetwork/angularModule/views/contact_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import blueCheckMark from 'vectors/blue-check-mark.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('contactForm', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $timeout = $injector.get('$timeout');
        const TranslationHelper = $injector.get('TranslationHelper');
        const StudentNetworkMessage = $injector.get('StudentNetworkMessage');

        return {
            restrict: 'E',
            scope: {
                careerProfile: '<',
                userInfo: '<',
                onFinish: '<',
            },
            templateUrl,

            link(scope) {
                scope.blueCheckMark = blueCheckMark;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                setupBrandNameProperties($injector, scope);

                const translationHelper = new TranslationHelper('student_network.contact_form');

                // initialize form content
                scope.message = StudentNetworkMessage.new({
                    recipient_id: scope.careerProfile.user_id,
                    reply_to: scope.currentUser.student_network_email || scope.currentUser.email,
                    subject: translationHelper.get('subject_placeholder', {
                        brandName: scope.brandNameShort,
                        sendersName: scope.currentUser.name,
                    }),
                });

                const distanceToRecipient = scope.currentUser.career_profile.distanceTo(
                    scope.careerProfile.place_details,
                );
                const userHasSharedAttributes =
                    scope.currentUser.career_profile.hasSharedStudentNetworkInterests(scope.careerProfile) ||
                    scope.currentUser.career_profile.hasSameCompany(scope.careerProfile) ||
                    scope.currentUser.career_profile.hasSameRole(scope.careerProfile) ||
                    scope.currentUser.career_profile.hasSharedSchools(scope.careerProfile);

                if (userAgentHelper.isSafari() || userAgentHelper.isiOSoriPadOSDevice()) {
                    scope.messageBodyPlaceholder = 'student_network.contact_form.message_placeholder_mobile_safari';
                }
                // If the current user and the recipient of the message are within 100 km of each other...
                else if (distanceToRecipient <= 100) {
                    scope.messageBodyPlaceholder = 'student_network.contact_form.message_placeholder_local';
                }
                // ... or if the current user and the recipient have certain attributes in common...
                else if (userHasSharedAttributes) {
                    scope.messageBodyPlaceholder = 'student_network.contact_form.message_placeholder_attrs';
                }
                // ... otherwise, use the default placeholder for the message body.
                else {
                    scope.messageBodyPlaceholder = 'student_network.contact_form.message_placeholder_default';
                }

                scope.sendMessage = () => {
                    scope.message.save().then(() => {
                        scope.messageSent = true;
                        $timeout(1750).then(() => {
                            scope.onFinish();
                        });
                    });
                };
            },
        };
    },
]);
