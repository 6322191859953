import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import 'FrontRoyalUiBootstrap/tooltip';
import template from 'StudentNetwork/angularModule/views/student_network_recommended_event_list_filters.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import iconAdvancedSearch from 'vectors/icon-advanced-search.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkRecommendedEventListFilters', [
    '$injector',
    function factory($injector) {
        const isMobileMixin = $injector.get('isMobileMixin');
        const StudentNetworkEvent = $injector.get('StudentNetworkEvent');
        const $timeout = $injector.get('$timeout');
        const $translate = $injector.get('$translate');

        return {
            restrict: 'E',
            scope: {
                eventsMapLayer: '<',
            },
            templateUrl,

            link(scope, elem) {
                scope.iconAdvancedSearch = iconAdvancedSearch;

                //--------------------
                // Initialization
                //--------------------

                isMobileMixin.onLink(scope);

                scope.filtersOpen = false;

                scope.eventTypeFilterOptions = StudentNetworkEvent.EVENT_TYPES.sort();

                scope.tooltipMessage = $translate.instant(
                    'student_network.student_network_recommended_event_list.recommended_tooltip',
                );

                //--------------------
                // User interface
                //--------------------

                scope.focusKeywordSearch = function focusKeywordSearch() {
                    elem.find('.filters-container input.keyword-search').focus();
                };

                scope.$watch('eventsMapLayer.advancedSearchOpen', () => {
                    // On desktop, when we open up the filters, we put the focus the first filter,
                    // which is the keyword search filter.
                    if (scope.eventsMapLayer && scope.eventsMapLayer.advancedSearchOpen && !scope.isMobile) {
                        $timeout(scope.focusKeywordSearch);
                    }
                });

                //--------------------
                // Filter/search interactions
                //--------------------

                scope.cancel = () => {
                    scope.eventsMapLayer.advancedSearchOpen = false;
                    scope.eventsMapLayer.resetServerFilters();
                    scope.showUpcomingEvents(true);
                };

                scope.showUpcomingEvents = forceFetchEvents => {
                    if (scope.eventsMapLayer.currentRecommendedEventsListTab !== 'upcoming' || forceFetchEvents) {
                        scope.eventsMapLayer.fetchCurrentAndUpcomingEvents();
                    }
                    scope.eventsMapLayer.currentRecommendedEventsListTab = 'upcoming';
                };

                scope.showFeaturedEvents = () => {
                    if (scope.eventsMapLayer.currentRecommendedEventsListTab !== 'featured') {
                        scope.eventsMapLayer.fetchFeaturedEvents();
                    }
                    scope.eventsMapLayer.currentRecommendedEventsListTab = 'featured';
                };

                scope.showPastEvents = () => {
                    if (scope.eventsMapLayer.currentRecommendedEventsListTab !== 'past_events') {
                        scope.eventsMapLayer.fetchPastEvents();
                    }
                    scope.eventsMapLayer.currentRecommendedEventsListTab = 'past_events';
                };

                scope.removeSelectedEventTypeFilterOption = eventType => {
                    if (!eventType) {
                        return;
                    }

                    const index = _.findIndex(
                        scope.eventsMapLayer.serverFilters.event_type,
                        _eventType => _eventType === eventType,
                    );
                    if (index > -1) {
                        scope.eventsMapLayer.serverFilters.event_type.splice(index, 1);
                    }
                };

                scope.onFilterChange = (newValue, oldValue) => {
                    // If the the filters changed, but the filters aren't open, don't bother applying
                    // the filters because the user probably clicked the cancel button, which fetches
                    // the events on its own, so we don't need to apply the filters again.
                    if (!scope.eventsMapLayer.advancedSearchOpen || _.isEqual(newValue, oldValue)) {
                        return;
                    }
                    scope.eventsMapLayer.applyServerFilters();
                };

                scope.$watch('eventsMapLayer.serverFilters.keyword_search', _.debounce(scope.onFilterChange, 500));
                scope.$watch('eventsMapLayer.serverFilters.places', scope.onFilterChange);
                scope.$watchCollection('eventsMapLayer.serverFilters.event_type', scope.onFilterChange);
            },
        };
    },
]);
