import { useEffect, useState } from 'react';
import $ from 'jquery';
import { angularDirectiveToReact } from 'Angular2reactHelper';

const EventMarkerAmap = angularDirectiveToReact('FrontRoyal.StudentNetwork', 'eventMarkerAmap', {
    amap: '<',
    group: '<',
    feature: '<',
    eventsMapLayer: '<',
});

export default function useEventMapGroup(AMap, map, userCohortIds, activeLayer, $injector) {
    const EventsMapLayer = $injector.get('EventsMapLayer');
    const [eventMapGroup, setEventMapGroup] = useState();
    const [eventFeatures, setEventFeatures] = useState();
    const [eventsMapLayer, setEventsMapLayer] = useState();
    const [eventMarkers, setEventMarkers] = useState();

    useEffect(() => {
        if (!AMap || !map || !userCohortIds) {
            return;
        }

        const group = new AMap.OverlayGroup();
        group.setMap(map);
        setEventMapGroup(group);

        const layer = new EventsMapLayer({ map, userCohortIds, handlers: { setEventFeatures }, AMap });
        setEventsMapLayer(layer);
    }, [AMap, map, userCohortIds, EventsMapLayer]);

    useEffect(() => {
        if (!eventsMapLayer || !eventMapGroup || !eventFeatures || !AMap) {
            return;
        }
        // eventMapGroup.clearOverlays();
        const markers = eventFeatures.map(feature => (
            <EventMarkerAmap
                key={feature.id}
                amap={AMap}
                group={eventMapGroup}
                feature={feature}
                eventsMapLayer={eventsMapLayer}
            />
        ));

        const overlays = eventMapGroup.getOverlays();
        const overlayFeatureIds = overlays.map(overlay => getOverlayMarkerId(overlay));

        const visibleFeatureIds = eventFeatures.map(feature => feature.id);
        const newly = visibleFeatureIds.filter(id => !overlayFeatureIds.includes(id));

        overlays.forEach(overlay => {
            if (visibleFeatureIds.indexOf(getOverlayMarkerId(overlay)) > -1) {
                overlay.show();
            } else {
                overlay.hide();
            }
        });

        if (newly.length > 0) {
            const currentMarkers = eventMarkers ? [...eventMarkers] : [];
            currentMarkers.push(markers.filter(marker => newly.indexOf(marker.key) > -1));
            setEventMarkers(currentMarkers);
        }

        function getOverlayMarkerId(overlay) {
            return $(overlay?.getContent())?.find('.event-marker')[0]?.dataset.id;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AMap, eventsMapLayer, eventMapGroup, eventFeatures]);

    useEffect(() => {
        if (!eventMapGroup) {
            return;
        }

        function isActive() {
            return activeLayer && activeLayer.name === 'events';
        }

        if (isActive()) {
            eventMapGroup.show();
        } else {
            eventMapGroup.hide();
        }
    }, [activeLayer, eventMapGroup]);

    return [eventsMapLayer, eventMarkers];
}
