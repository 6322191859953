
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event = window.Smartly.locales.modules.en.back_royal.student_network.student_network_event || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event = {...window.Smartly.locales.modules.en.back_royal.student_network.student_network_event, ...{
    "meetup": "Meetup",
    "book_club": "Book Club",
    "company_visit": "Business Visits",
    "special_event": "Special Event",
    "conference": "Conference",
    "career_fair": "Career Fair",
    "admissions_event": "Admissions Event",
    "online_event": "Virtual Meetup",
    "virtual_meetup": "Virtual Location-Based Meetup",
    "documentary_club": "Documentary Club",
    "podcast_club": "Podcast Club",
    "ted_talk_club": "TEDTalk Club",
    "student_organization_events": "Student Organization Events",
    "ask_me_anything": "Ask Me Anything",
    "office_hours": "Office Hours",
    "academic_webinar": "Academic Webinar"
}
}
    