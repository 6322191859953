import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';

angularModule.factory('StudentNetworkMap', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('student_network_maps');
            this.alias('StudentNetworkMap');

            return {};
        });
    },
]);
