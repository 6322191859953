import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
// Use a full path in the import here to prevent from importing
// more than we need
import { EVENT_TYPE_CONFIGS_MAP } from 'StudentNetworkEvent/StudentNetworkEventTypeConfigs';
import { icon, mappable, hidesLocationDetails } from 'StudentNetworkEvent/helpers';
import moment from 'moment-timezone';
import { regionAwareImageUrlForFormat } from 'regionAwareImage';

angularModule.factory('StudentNetworkEvent', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const HasLocation = $injector.get('HasLocation');
        const dateHelper = $injector.get('dateHelper');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.setCollection('student_network_events');
            this.alias('StudentNetworkEvent');

            this.include(HasLocation);

            this.EVENT_TYPE_CONFIGS_MAP = EVENT_TYPE_CONFIGS_MAP;
            this.EVENT_TYPES = Object.keys(EVENT_TYPE_CONFIGS_MAP);
            this.MAPPABLE_EVENT_TYPES = _.filter(
                Object.keys(EVENT_TYPE_CONFIGS_MAP),
                eventType => EVENT_TYPE_CONFIGS_MAP[eventType].mappable,
            );

            this.setCallback('after', 'copyAttrsOnInitialize', function afterCopyAttrsOnInitialize() {
                this.rsvp_status = this.rsvp_status || 'not_required';
                this.place_details = this.place_details || {};
                this.target_institution_ids = this.target_institution_ids || [];
                this.target_program_types = this.target_program_types || [];
                this.target_cohort_ids = this.target_cohort_ids || [];
                this.target_student_statuses = this.target_student_statuses || [];
            });

            // hidesLocationDetails is meant for event types that have a location, but aren't meeting
            // at that physical location, so the location details section should be hidden.
            Object.defineProperty(this.prototype, 'hidesLocationDetails', {
                get() {
                    return hidesLocationDetails(this);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'icon', {
                get() {
                    return icon(this);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'mappable', {
                get() {
                    return mappable(this);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'imageSrc', {
                get() {
                    return regionAwareImageUrlForFormat(
                        this.image,
                        'original',
                        $injector.get('ConfigFactory').getSync(true),
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'startTime', {
                get() {
                    if (!this.$$startTime) {
                        this.$$startTime = new Date(this.start_time * 1000);
                    }
                    return this.$$startTime;
                },
            });

            Object.defineProperty(this.prototype, 'endTime', {
                get() {
                    if (!this.$$endTime) {
                        this.$$endTime = new Date(this.end_time * 1000);
                    }
                    return this.$$endTime;
                },
            });

            Object.defineProperty(this.prototype, 'multiDay', {
                get() {
                    // Note: anonymized events don't have an end_time, so we have to be cognizant of that
                    if (this.anonymized) {
                        return false;
                    }
                    return dateHelper.onDifferentDays(
                        this.start_time * 1000,
                        this.end_time * 1000,
                        this.timezone || moment.tz.guess(),
                    );
                },
            });

            // Append the url to the notes field, since we don't know which
            // calendars will respect the ICS URL field or have a URL param field
            // (thus far it seems like only Apple Calendar does).
            Object.defineProperty(this.prototype, 'descriptionWithUrl', {
                get() {
                    let descriptionWithUrl = this.description;
                    if (this.external_rsvp_url) {
                        descriptionWithUrl += `\n\n${this.external_rsvp_url}`;
                    }
                    return descriptionWithUrl;
                },
            });

            return {
                // We have some helper functions that were born out of necessity. We need
                // to do lots of different date handling for student network events, touching
                // the different timezones and formatting options.
                //
                // See https://momentjs.com/docs/#/displaying/format/ for formatting options.

                formattedStartDateTime(format, fallbackTimezone) {
                    return dateHelper.formattedUserFacingDayDateTime(
                        this.start_time * 1000,
                        format,
                        this.timezone || fallbackTimezone || moment.tz.guess(),
                    );
                },

                formattedRelativeStartDayOrDateTime(format, fallbackTimezone) {
                    return dateHelper.formattedUserFacingRelativeDayDate(
                        this.start_time * 1000,
                        format,
                        this.timezone || fallbackTimezone || moment.tz.guess(),
                    );
                },

                formattedEndDateTime(format, fallbackTimezone) {
                    return dateHelper.formattedUserFacingDayDateTime(
                        this.end_time * 1000,
                        format,
                        this.timezone || fallbackTimezone || moment.tz.guess(),
                    );
                },
            };
        });
    },
]);
