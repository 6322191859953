
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.student_network = window.Smartly.locales.modules.en.back_royal.student_network || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_type_filters = window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_type_filters || {};
window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_type_filters = {...window.Smartly.locales.modules.en.back_royal.student_network.student_network_event_type_filters, ...{
    "title": "Filter by Event Type",
    "submit_button_text": "Apply"
}
}
    