import { useEffect, useState } from 'react';

export default function useStudentNetworkMap(AMap, container, currentUser) {
    const defaultZoom = 3;
    const minZoom = 3;
    const maxZoom = 10;

    const [center, setCenter] = useState(null);
    const [map, setMap] = useState();
    const [mapInfo, setMapInfo] = useState();

    // calculate map center
    useEffect(() => {
        const userPlaceDetails = currentUser && currentUser.career_profile && currentUser.career_profile.place_details;

        // set the center of the map based on window width
        const width = window.innerWidth; // US only

        // eslint-disable-next-line no-nested-ternary
        const centerLat = width > 1000 ? 23.45 : userPlaceDetails ? userPlaceDetails.lat : 30;
        let centerLng = userPlaceDetails ? userPlaceDetails.lng : -100;

        if (width > 1400) {
            // US, EU, Asia
            centerLng = -7.39;
        } else if (width > 1000) {
            // US, EU
            centerLng = -42;
        }

        setCenter([centerLng, centerLat]);
    }, [currentUser]);

    // create amap
    useEffect(() => {
        if (!AMap || !center) {
            return undefined;
        }

        const opt = {
            zoom: defaultZoom,
            zoomEnable: true,
            zooms: [minZoom, maxZoom],
            mapStyle: 'amap://styles/8c815fdad56b315d0c85173e4924c1c9',
        };
        if (center[0] && center[1]) {
            opt.center = center;
        }

        const instance = new AMap.Map(container, opt);

        const toolbar = new AMap.ToolBar({
            position: 'RB',
        });

        instance.addControl(toolbar);

        setMap(instance);

        return () => {
            instance.destroy();
        };
    }, [AMap, container, center]);

    // add map event listener to refresh mapInfo state.
    useEffect(() => {
        if (!map) {
            return;
        }

        function _paddedViewportBounds(currentBounds) {
            const sw = currentBounds.getSouthWest();
            const ne = currentBounds.getNorthEast();

            const furtherSW = new AMap.LngLat(sw.getLng(), sw.getLat());
            const furtherNE = new AMap.LngLat(ne.getLng(), ne.getLat());

            return [furtherSW, furtherNE];
        }

        function _getViewports(furtherBounds) {
            const [furtherSW, furtherNE] = furtherBounds;
            let furtherViewports = [];

            const lat1 = furtherSW.getLat();
            const lat2 = furtherNE.getLat();
            const lng1 = furtherSW.getLng();
            const lng2 = furtherNE.getLng();

            const crossesDateLine = lng1 > lng2;
            const distanceToExtend = 10;
            // make sure the marker can show when the window width >= 2100 or the lng1 and lng2 are close but the window is normal with
            const needExtend = window.innerWidth >= 2100 || Math.abs(lng1 - lng2) < distanceToExtend;

            // add naive single viewport based on bounds (might cross date line)
            furtherViewports.push([lng1, lat1, lng2, lat2]);

            if (crossesDateLine || needExtend) {
                // adjust first viewport to be the part of the map to the right of the date line
                // adjust its longitude
                furtherViewports[0][0] = -179.999;

                // create a second viewport that is the part of the map to the left of the date line
                furtherViewports.push([needExtend ? lng2 : lng1 - distanceToExtend, lat1, 180, lat2]);
            }

            furtherViewports = furtherViewports.map(vp => vp.join(','));

            return furtherViewports;
        }

        const onMapInfoChanged = () => {
            const zoom = map.getZoom();
            const bounds = map.getBounds();
            const furtherBounds = _paddedViewportBounds(bounds);
            const viewports = _getViewports(furtherBounds);

            const info = { zoom, viewports };
            setMapInfo(info);
        };

        map.on('dragend', onMapInfoChanged);
        map.on('zoomend', onMapInfoChanged);
        map.on('complete', onMapInfoChanged);
        map.on('resize', () => setTimeout(onMapInfoChanged, 500));
    }, [AMap, map]);

    return [map, mapInfo];
}
