import { type ProgramType } from 'Program';
import { StudentNetworkQuickFilterKey, type StudentNetworkQuickFilterConfig } from './StudentNetwork.types';

export function studentNetworkQuickFilterConfigs(studentsMapLayer: {
    mbaProgramTypeFilterValues: ProgramType[];
    embaProgramTypeFilterValues: ProgramType[];
    execEdProgramTypeFilterValues: ProgramType[];
    msbaProgramTypeFilterValues: ProgramType[];
    msseProgramTypeFilterValues: ProgramType[];
}): Record<StudentNetworkQuickFilterKey, StudentNetworkQuickFilterConfig> {
    return {
        [StudentNetworkQuickFilterKey.allMba]: {
            key: [...studentsMapLayer.mbaProgramTypeFilterValues],
            name: StudentNetworkQuickFilterKey.allMba,
            label: { localeKey: 'student_network.network_map_filter_box.mba' },
        },
        [StudentNetworkQuickFilterKey.allEmba]: {
            key: [...studentsMapLayer.embaProgramTypeFilterValues],
            name: StudentNetworkQuickFilterKey.allEmba,
            label: { localeKey: 'student_network.network_map_filter_box.emba' },
        },
        [StudentNetworkQuickFilterKey.allExecutiveEducation]: {
            key: [...studentsMapLayer.execEdProgramTypeFilterValues],
            name: StudentNetworkQuickFilterKey.allExecutiveEducation,
            label: { localeKey: 'student_network.network_map_filter_box.executive_education' },
        },
        [StudentNetworkQuickFilterKey.allMsba]: {
            key: [...studentsMapLayer.msbaProgramTypeFilterValues],
            name: StudentNetworkQuickFilterKey.allMsba,
            label: { localeKey: 'student_network.network_map_filter_box.msba' },
        },
        [StudentNetworkQuickFilterKey.allMsse]: {
            key: [...studentsMapLayer.msseProgramTypeFilterValues],
            name: StudentNetworkQuickFilterKey.allMsse,
            label: { localeKey: 'student_network.network_map_filter_box.msse' },
        },
    };
}
