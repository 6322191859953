import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
/*
    This overlay is placed on the Google Map with a given element and Lat/Lng location.
    Directives can create a GoogleOverlayView around their own view element,
    which results in the element getting moved into the map. This class then ensures that
    the overlay is positioned in the appropriate location.
*/
angularModule.factory('GoogleOverlayView', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');
        let initialized = false;

        function GoogleOverlayView(element, latlng) {
            if (!initialized) {
                throw new Error('GoogleOverlayView not yet initialized');
            }
            this.element = element;
            this.latlng = latlng;
        }

        // We have to delay this until google maps is loaded
        function onGoogleApiLoaded() {
            GoogleOverlayView.prototype = new $window.google.maps.OverlayView();

            GoogleOverlayView.prototype.onAdd = function () {
                const panes = this.getPanes();
                panes.overlayImage.appendChild(this.element[0]);
            };

            GoogleOverlayView.prototype.onRemove = function () {
                this.element.remove();
            };

            GoogleOverlayView.prototype.draw = function () {
                const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
                if (point) {
                    this.element.css('left', `${point.x}px`);
                    this.element.css('top', `${point.y}px`);
                }
            };

            initialized = true;
        }

        GoogleOverlayView.onGoogleApiLoaded = onGoogleApiLoaded;

        return GoogleOverlayView;
    },
]);
