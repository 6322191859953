import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_profile_list.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupStyleHelpers } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentProfileList', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');

        return {
            scope: {
                careerProfiles: '<',
                listLimit: '<',
                offset: '<',
                noMoreAvailable: '<',
                getProfiles: '&',
                clusterLat: '<?',
                clusterLong: '<?',
                removeLatLong: '&',
                totalCount: '<',
                showingProfiles: '=?', // two-way bound so the parent knows
            },
            restrict: 'E',
            templateUrl,
            link(scope) {
                setupStyleHelpers($injector, scope);

                // Referenced inside of AppBrandMixin.
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.internalGetProfiles = (offset, action, listLimit) => {
                    scope.getProfiles({
                        offset,
                        action,
                        listLimit,
                    });
                };

                function setLimitedProfiles() {
                    scope.limitedCareerProfiles = scope.careerProfiles.slice(
                        scope.offset,
                        scope.offset + scope.listLimit,
                    );
                    scope.showingProfiles = scope.limitedCareerProfiles.length > 0;
                }

                scope.$watchCollection('careerProfiles', setLimitedProfiles);
                scope.$watchGroup(['listLimit', 'offset'], setLimitedProfiles);
            },
        };
    },
]);
