import angularModule from './student_network_module';

angularModule.factory('StudentNetworkMapViewModel', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const StudentsMapLayer = $injector.get('StudentsMapLayer');
        const EventsMapLayer = $injector.get('EventsMapLayer');
        const $window = $injector.get('$window');
        const GoogleOverlayView = $injector.get('GoogleOverlayView');
        const guid = $injector.get('guid');
        const $timeout = $injector.get('$timeout');
        const Cohort = $injector.get('Cohort');
        const DialogModal = $injector.get('DialogModal');
        const $location = $injector.get('$location');
        const StudentNetworkEvent = $injector.get('StudentNetworkEvent');
        const $q = $injector.get('$q');

        let googleMaps;

        const StudentNetworkMapViewModel = SuperModel.subclass(function () {
            this.extend({
                // There are some things that cannot be initialized until
                // the google maps api is loaded.  network-map-dir is responsible
                // for doing this before initializing a StudentNetworkMapViewModel
                onGoogleApiLoaded() {
                    if (!this.ranOnGoogleApiLoaded) {
                        GoogleOverlayView.onGoogleApiLoaded();
                        this.ranOnGoogleApiLoaded = true;
                        googleMaps = $window.google.maps;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'isAMap', {
                get() {
                    // There is no obvious attribute of Google Map to distinguish who is it, so use the AMap's CLASS_NAME.
                    return this.map?.CLASS_NAME === 'AMap.Map';
                },
            });

            Object.defineProperty(this.prototype, 'loadingData', {
                get() {
                    return this.dataLayers.some(layer => layer?.loading);
                },
            });

            return {
                // Only the AMap will give the 'viewModelHandlers', 'studentMapLayerHandlers' & 'eventsMapLayer' parameters.
                initialize(
                    map,
                    userCohortIds,
                    eventId,
                    showEvents,
                    viewModelHandlers,
                    studentMapLayerHandlers,
                    eventsMapLayer,
                ) {
                    // In order to get the 'isAMap' property, the map assignment must be advanced.
                    this.map = map;
                    if (!this.isAMap && !StudentNetworkMapViewModel.ranOnGoogleApiLoaded) {
                        throw new Error('onGoogleApiLoaded has not yet been called.');
                    }

                    this._id = guid.generate();
                    this.eventId = eventId;
                    this.showEvents = showEvents;
                    const location = $location.search();
                    this.initialSearchKeyword = location.keyword;
                    this.showFeaturedEvents = location['show-featured-events'];
                    this.myClass = location.myClass;
                    this.byIds = location.ids;
                    this.userCohortIds = userCohortIds;

                    this.studentsMapLayer = new StudentsMapLayer(map, studentMapLayerHandlers);
                    // in the AMap case, the eventsMapLayer will be passed in.  In the Google case we have to create one.
                    if (eventsMapLayer) {
                        this.eventsMapLayer = eventsMapLayer;
                        this.eventsMapLayer.studentNetworkMapViewModel = this;
                    } else {
                        this.eventsMapLayer = new EventsMapLayer({
                            map,
                            studentNetworkMapViewModel: this,
                            userCohortIds: this.userCohortIds,
                        });
                        // set up event listeners
                        this._listeners = [
                            googleMaps.event.addListener(map, 'idle', _.debounce(this._onIdleEvent.bind(this), 500)),
                        ];
                        $($window).on(`resize.${this._id}`, this._updateMapSize.bind(this));
                    }

                    this.dataLayers = [this.studentsMapLayer, this.eventsMapLayer];
                    this.viewModelHandlers = viewModelHandlers;
                    // initially fetch and render the data
                    $timeout(() => {
                        this.setDefaultActiveLayer();
                    }, 500);
                },

                setDefaultActiveLayer() {
                    // If the user navigated to the student network via a URL
                    // with an `event-id` query parameter (passed in through
                    // $routeProvider), then we need to set the default layer
                    // to the "events" layer, and kick off the process of fetching
                    // and showing that particular event'd details.
                    if (this.eventId || this.showEvents || this.showFeaturedEvents) {
                        this.setActiveMapLayer(this.eventsMapLayer);
                        if (this.eventId) {
                            this.fetchEventAndShowDetails();
                        }
                        if (this.showFeaturedEvents) {
                            this.activeMapLayer.currentRecommendedEventsListTab = 'featured';
                            this.activeMapLayer.fetchFeaturedEvents();
                        }
                    } else {
                        // Apply initial search, if there is one, before activating layer so that map bubbles update when activated
                        this.applyInitialSearch();
                        this.setActiveMapLayer(this.studentsMapLayer);
                    }
                },

                setActiveMapLayer(layer) {
                    if (this.activeMapLayer === layer) {
                        return;
                    }
                    // If current map is AMap, it needs to call the callback function which comes with the viewModelHandlers parameter.
                    this.viewModelHandlers?.setActiveLayer(layer);
                    this.activeMapLayer = layer;
                    this.activeMapLayer.activate();
                    this.activeMapLayer.refresh();
                    _.chain(this.dataLayers).without(this.activeMapLayer).invokeMap('deactivate').value();
                },

                applyInitialSearch() {
                    if (!this.initialSearchKeyword && !this.byIds && !this.myClass) return;

                    if (this.initialSearchKeyword) {
                        this.studentsMapLayer.advancedFilters.keyword_search = this.initialSearchKeyword;
                        this.studentsMapLayer.inAdvancedSearchMode = true;
                    }
                    if (this.byIds) {
                        this.studentsMapLayer.advancedFilters.user_id = this.byIds;
                    }
                    if (this.myClass) {
                        this.studentsMapLayer.advancedFilters.class = ['mine'];
                    }
                    this.studentsMapLayer.inAdvancedSearchMode = true;
                    this.studentsMapLayer.applyAdvancedFilters();
                },

                updateMyClassCohortId(currentUser) {
                    const cohort = currentUser?.cohortForActiveProgramInclusion;

                    // Note: if you deferred into a cohort that hasn't yet reached its registration deadline,
                    // we don't want you to be able to filter by My Class and potentially very few
                    // people in the network
                    // This could change if we changed our policy of accepting everybody at once.
                    if (
                        currentUser &&
                        currentUser.hasAccessToFullStudentNetworkProfiles &&
                        cohort &&
                        Cohort.canFilterNetworkForMyClass(cohort.program_type) &&
                        cohort.registrationDeadlineHasPassed
                    ) {
                        _.forEach(this.dataLayers, layer => {
                            layer.myClassCohortId = cohort.id;
                        });
                    } else {
                        _.forEach(this.dataLayers, layer => {
                            layer.myClassCohortId = null;
                        });
                    }
                },

                destroy() {
                    _.invokeMap(this.dataLayers, 'deactivate');
                    if (!this.isAMap) {
                        _.invokeMap(this._listeners, 'remove');
                        $($window).off(`resize.${this._id}`);
                    }
                    DialogModal.hideAlerts();
                },

                //--------------------
                // Event details
                //--------------------

                fetchEventAndShowDetails() {
                    this.fetchEventForId(this.eventId)
                        .then(response => {
                            if (response.result[0]) {
                                this.showEventDetailsModal(response.result[0]);
                                return $q.resolve();
                            }
                            return $q.reject();
                        })
                        .catch(() => {
                            $location.search('event-id', null);
                        });
                },

                fetchEventForId(eventId) {
                    return StudentNetworkEvent.index({
                        filters: {
                            id: eventId,
                            start_time: 0,
                            end_time: new Date('2099/01/01').getTime(),
                            include_tbd: true,
                            cohort_ids: this.userCohortIds,
                        },
                    })
                        .then(response => $q.resolve(response))
                        .catch(() => $q.reject());
                },

                showEventDetailsModal(event) {
                    if (!event) {
                        return;
                    }

                    const classes = [
                        'no-body-padding',
                        'small-close-button',
                        'student-network-event-details',
                        'clickable-app-header-menu',
                    ];

                    if (event.anonymized) classes.push('anonymized');

                    $location.search('event-id', event.id);
                    DialogModal.alert({
                        content:
                            '<student-network-event-details event="event" container-selector="containerSelector"></student-network-event-details>',
                        blurTargetSelector: '#app-main-container',
                        classes,
                        animated: false,
                        closeOnOutsideClick: true,
                        close: () => {
                            $location.search('event-id', null);
                        },
                        scope: {
                            event,
                            containerSelector: 'dialog-modal-alert .modal-content',
                        },
                    });
                },

                //--------------------
                // Map Interactions
                //--------------------

                _onIdleEvent() {
                    if (!this.activeMapLayer.supportsRefreshOnIdleMapEvent) {
                        return;
                    }

                    this.activeMapLayer.refresh();
                },

                //--------------------
                // Map Resizing
                //--------------------

                _updateMapSize() {
                    const center = this.map.getCenter();
                    googleMaps.event.trigger(this.map, 'resize');
                    this.map.setCenter(center);
                },
            };
        });

        return StudentNetworkMapViewModel;
    },
]);
