import angularModule from 'StudentNetwork/angularModule/scripts/student_network_module';
import template from 'StudentNetwork/angularModule/views/student_network_event_type_filters.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkEventTypeFilters', [
    '$injector',
    function factory($injector) {
        const StudentNetworkEvent = $injector.get('StudentNetworkEvent');

        return {
            restrict: 'E',
            scope: {
                eventsMapLayer: '<',
            },
            templateUrl,
            link(scope) {
                //----------------------------
                // Initialization
                //----------------------------

                scope.StudentNetworkEvent = StudentNetworkEvent;

                scope.appliedFiltersProxy = _.clone(scope.eventsMapLayer.mapFilters.eventTypes) || [];

                //----------------------------
                // Scope Methods
                //----------------------------

                scope.eventTypeSelected = eventType => _.includes(scope.appliedFiltersProxy, eventType);

                scope.onFilterButtonClick = eventType => {
                    const index = _.findIndex(
                        scope.appliedFiltersProxy,
                        selectedEventType => selectedEventType === eventType,
                    );
                    if (index >= 0) {
                        scope.appliedFiltersProxy.splice(index, 1);
                    } else {
                        scope.appliedFiltersProxy.push(eventType);
                    }
                };

                scope.iconForEventType = eventType => StudentNetworkEvent.EVENT_TYPE_CONFIGS_MAP[eventType].icon;

                //----------------------------
                // Blur handling
                //----------------------------

                const blurTarget = $('#network-map-container > ng-map');

                const initialWekbitFilter = blurTarget.css('-webkit-filter');
                const initalFilter = blurTarget.css('filter');

                blurTarget.css('-webkit-filter', 'blur(3px)');
                blurTarget.css('filter', 'blur(3px)');

                scope.$on('$destroy', () => {
                    blurTarget.css('-webkit-filter', initialWekbitFilter || 'none');
                    blurTarget.css('filter', initalFilter || 'none');
                });
            },
        };
    },
]);
